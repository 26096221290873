import React, { useEffect, useState } from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  amountDisplay,
  formatDollarsToCents,
  formatCentsToDollars,
} from "@/utils/money";
import {
  getEvent,
  getEventPeople,
  getEventSettlementInfo,
  getEventOfferVariables,
} from "@/queries/events";
import { getVenue } from "@/queries/venues";
import { getEventRollups, getEventPDFExpenses } from "@/queries/accounting";
import { localTime, displayDateTime } from "@/utils/datetime";
import MammothLogo from "@/components/MammothLogo";

const Missing = (props) => {
  const { text } = props;
  return <span className="bg-red-100 p-1">{text}</span>;
};

const Tickets = (props) => {
  const { manifests, ticketFees } = props;

  console.log("Manifests");
  console.dir(manifests);
  return (
    <table className="w-full text-sm border-dashed border-gray-400 border">
      <tbody>
        <tr>
          <th className="text-center bg-gray-300 font-montserrat font-extrabold">
            TICKETING
          </th>
          <th className="text-center bg-blue-300 font-montserrat font-extrabold">
            CAPACITY
          </th>
          <th className="text-center bg-blue-300 font-montserrat font-extrabold">
            COMPS
          </th>
          <th className="text-center bg-blue-300 font-montserrat font-extrabold">
            NET PRICE
          </th>
          <th className="text-center bg-blue-300 font-montserrat font-extrabold">
            TICKET FEES
          </th>
          <th className="text-center bg-blue-300 font-montserrat font-extrabold">
            GROSS PRICE
          </th>
          <th className="text-center bg-blue-300 font-montserrat font-extrabold">
            POTENTIAL
          </th>
        </tr>
        {manifests.map((manifest, index) => (
          <tr key={index}>
            <td className="text-right bg-blue-300 font-montserrat font-extrabold px-1">
              {manifest.name}
            </td>
            <td className="text-right font-montserrat font-extrabold px-1">
              {manifest.qty}
            </td>
            <td className="text-right font-montserrat font-extrabold px-1">
              {manifest.comps}
            </td>
            <td className="text-right font-montserrat font-extrabold px-1">
              {amountDisplay(manifest.price)}
            </td>
            <td className="text-right font-montserrat font-extrabold px-1">
              {amountDisplay(ticketFees)}
            </td>
            <td className="text-right font-montserrat font-extrabold px-1">
              {amountDisplay(manifest.price + ticketFees)}
            </td>
            <td className="text-right font-montserrat font-extrabold px-1">
              {amountDisplay((manifest.price + ticketFees) * manifest.qty)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Expenses = (props) => {
  const { eventID } = props;
  const expenses = useQuery(["expenses-offer-pdf", eventID], () =>
    getEventPDFExpenses(eventID)
  );

  if (expenses.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  const advertisingExpenses = expenses.data.filter(
    (expense) => expense.group_name === "Advertising"
  );
  const totalAdvertisingExpenses = advertisingExpenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );
  const displayExpenses = expenses.data.filter(
    (expense) => expense.group_name !== "Advertising"
  );

  return (
    <>
      <table className="w-full text-sm border-dashed border-gray-400 border">
        <tbody>
          {displayExpenses.map((expense, index) => (
            <tr key={`expense-${index}`}>
              <th className="text-left bg-white font-montserrat font-normal px-1">
                {expense.subgroup_name && expense.subgroup_name.toUpperCase()}
              </th>
              <td className=" bg-blue-300 font-montserrat px-1 w-48 relative">
                <span className="mr-auto inline-block">
                  {amountDisplay(expense.amount)}
                </span>
                <span className="absolute right-0.5 w-24 truncate">
                  {expense.notes}
                </span>
              </td>
            </tr>
          ))}
          <tr key="advertising-total">
            <th className="text-left bg-gray-300 font-montserrat font-semibold px-1">
              ADVERTISING TOTAL
            </th>
            <td className="text-right bg-gray-300 font-montserrat font-semibold px-1">
              {amountDisplay(totalAdvertisingExpenses)}
            </td>
          </tr>
          {advertisingExpenses.map((expense, index) => (
            <tr key={`expense-${index}`}>
              <th className="text-left bg-white font-normal px-1">
                {expense.subgroup_name && expense.subgroup_name.toUpperCase()}
              </th>
              {console.log("huxxxxx, ", expense.amount)}
              <td className="text-right bg-blue-300 px-1">
                {expense.amount > 0 && amountDisplay(expense.amount)}
              </td>
            </tr>
          ))}
          <tr key="advertising-total-2">
            <th className="text-left bg-gray-300 px-1 font-montserrat font-semibold">
              ADVERTISING TOTAL
            </th>
            <td className="text-right bg-gray-300 font-montserrat font-semibold px-1">
              {amountDisplay(totalAdvertisingExpenses)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const DoorDeal = (props) => {
  const { eventID } = props;
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));
  const expenses = useQuery(["expenses-offer-pdf", eventID], () =>
    getEventPDFExpenses(eventID)
  );
  const variables = useQuery(["variables-offer-pdf", eventID], () =>
    getEventOfferVariables(eventID)
  );

  if (expenses.isLoading || variables.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  return (
    <div className="border border-slate-900 w-full">
      <div className="w-full bg-gray-300 text-center text-sm font-semibold">
        SPLIT POINT
      </div>
      <div className="w-full flex justify-between p-1 text-sm">
        <span>TOTAL EXPENSES</span>
        <span>
          {amountDisplay(
            variables.data.total_expenses + variables.data.variables_total
          )}
        </span>
      </div>
      <div className="w-full flex justify-between p-1 text-sm">
        <span>PROMOTER PROFIT</span>
        <span>{amountDisplay(variables.data.promoter_profit)}</span>
      </div>
      <div className="w-full flex justify-between p-1 text-sm">
        <span className="font-semibold">SPLIT POINT</span>
        <span>{amountDisplay(variables.data.split_point)}</span>
      </div>
    </div>
  );
};


const SplitPoint = (props) => {
  const { eventID } = props;
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));
  const expenses = useQuery(["expenses-offer-pdf", eventID], () =>
    getEventPDFExpenses(eventID)
  );
  const variables = useQuery(["variables-offer-pdf", eventID], () =>
    getEventOfferVariables(eventID)
  );

  if (expenses.isLoading || variables.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  return (
    <div className="border border-slate-900 w-full">
      <div className="w-full bg-gray-300 text-center text-sm font-semibold">
        SPLIT POINT
      </div>
      <div className="w-full flex justify-between p-1 text-sm">
        <span>TOTAL EXPENSES</span>
        <span>
          {amountDisplay(
            variables.data.total_expenses + variables.data.variables_total
          )}
        </span>
      </div>
      <div className="w-full flex justify-between p-1 text-sm">
        <span>PROMOTER PROFIT</span>
        <span>{amountDisplay(variables.data.promoter_profit)}</span>
      </div>
      <div className="w-full flex justify-between p-1 text-sm">
        <span className="font-semibold">SPLIT POINT</span>
        <span>{amountDisplay(variables.data.split_point)}</span>
      </div>
    </div>
  );
};

const TheDeal = (props) => {
  const { eventID } = props;
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));
  const variables = useQuery(["variables-offer-pdf", eventID], () =>
    getEventOfferVariables(eventID)
  );

  if (event.isLoading || variables.isLoading) {
    return <div className="font-montserrat">Loading...</div>;
  }

  let dealInfo = (
    <span className="font-montserrat font-extrabold">
      PLUS {event.data.offer_percentage}% After split point.
    </span>
  );

  if (event.data.offer_type == "nbor") {
    dealInfo = (
      <span className="font-montserrat font-extrabold">
        DOOR DEAL {event.data.offer_percentage}% after catering and support
      </span>
    );
  }

  if (event.data.offer_type == "vs") {
    dealInfo = (
      <span className="font-montserrat font-extrabold">
        VERSUS {event.data.offer_percentage}% of net receipts after approved
        expenses.
      </span>
    );
  }
  return (
    <div className="border border-slate-300">
      <div className="w-full flex">
        <div className="w-1/3 bg-gray-300 text-xl text-center font-extrabold font-montserrat p-2">
          THE DEAL
        </div>
        <div className="w-2/3 flex justify-between text-xl align-middle p-2">
          <span className="font-montserrat font-extrabold">$</span>
          <span className="font-montserrat font-extrabold">
            {amountDisplay(variables.data.the_deal_amount).replace("$", "")}
          </span>
        </div>
      </div>
      <div className="border-t border-slate-300 text-center font-semibold p-3">
        <p className="my-4 font-montserrat">{dealInfo}</p>
        <p className="my-4"></p>
      </div>
      <div className="border-t border-slate-3000 text-center font-semibold p-3">
        <p className="mt-4 dark:text-white">{event.data.offer_notes || "-"}</p>
      </div>
    </div>
  );
};

const Variables = (props) => {
  const [venueID, setVenueID] = useState(null);
  const { eventID, rollups } = props;
  const expenses = useQuery(["expenses-offer-pdf", eventID], () =>
    getEventPDFExpenses(eventID)
  );
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));
  const variables = useQuery(["variables-offer-pdf", eventID], () =>
    getEventOfferVariables(eventID)
  );

  const venue = useQuery(["venue-detail", venueID], () => getVenue(venueID));

  useEffect(() => {
    if (!venueID && event?.data?.venue) {
      setVenueID(event.data.venue.id);
    }
  }, [event.data]);

  if (expenses.isLoading || event.isLoading || variables.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  return (
    <table className="w-full border-dashed border-gray-400 border">
      <tbody>
        <tr>
          <th className="w-1/2 bg-gray-300 text-left font-extrabold font-montserrat text-sm px-1">
            VARIABLES TOTAL
          </th>
          <td className="w-1/2 bg-gray-300 text-right font-extrabold text-sm px-1">
            {amountDisplay(
              variables.data.variables_total + event.data.offer_comp_tax
            )}
          </td>
        </tr>
        <tr>
          <th className="w-1/2 bg-white text-left font-normal font-montserrat text-sm px-1">
            ASCAP/BMI/SESAC
          </th>
          <td className="w-1/2 bg-blue-300 text-right font-normal font-montserrat text-sm px-1">
            {amountDisplay(
              (!event.data.has_ascap ? 0 : variables.data.ascap) +
                (!event.data.has_bmi ? 0 : variables.data.bmi) +
                (!event.data.has_sesac ? 0 : variables.data.sesac) +
                (!event?.data.offeer_has_gmr ? 0 : variables.data?.offer_gmr)
            )}
          </td>
        </tr>
        <tr>
          <th className="w-1/2 bg-white text-left font-normal font-montserrat text-sm px-1">
            COMP TAX
          </th>
          <td className="w-1/2 bg-blue-300 text-right font-normal font-montserrat text-sm px-1">
            {event.data.offer_has_comp_tax
              ? amountDisplay(event.data.offer_comp_tax)
              : "$0.00"}
          </td>
        </tr>
        <tr>
          <th className="w-1/2 bg-white text-left font-normal font-montserrat text-sm px-1">
            CREDIT CARDS
          </th>
          <td className="w-1/2 bg-blue-300 text-right font-normal font-montserrat text-sm px-1">
            {event.data.has_credit_card_fees
              ? amountDisplay(variables.data.credit_card_fees)
              : "$0.00"}
          </td>
        </tr>
        <tr>
          <th className="w-1/2 bg-white text-left font-normal font-montserrat text-sm px-1">
            INSURANCE
          </th>
          <td className="w-1/2 bg-blue-300 text-right font-normal font-montserrat text-sm px-1">
            {event.data.has_insurance
              ? amountDisplay(variables.data.insurance_amount)
              : "$0.00"}
          </td>
        </tr>
        <tr>
          <th className="w-1/2 bg-white text-left font-normal font-montserrat text-sm px-1">
            RENT
          </th>
          <td className="w-1/2 bg-blue-300 text-right font-normal font-montserrat text-sm px-1">
            {!event.data.offer_has_rent
              ? "$0.00"
              : amountDisplay(event.data.tickets_total * 100)}
          </td>
        </tr>
        <tr>
          <th className="w-1/2 bg-white text-left text-sm">
            TICKET COMMISSION
          </th>
          <td className="w-1/2 bg-blue-300 text-right font-semibold text-sm pr-1">
            {!event.data.has_ticket_commission
              ? "$0.00"
              : amountDisplay(variables.data.ticket_commission)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const VariablesSubText = (props) => {
  const { eventID } = props;
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));

  return (
    <table className="w-full">
      <tbody>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            -
          </td>
        </tr>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            -
          </td>
        </tr>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            -
          </td>
        </tr>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            3.50%
          </td>
        </tr>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            55¢/head
          </td>
        </tr>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            $1/per
          </td>
        </tr>
        <tr>
          <td className="w-full text-right font-normal font-montserrat text-sm px-1">
            3%/CAP
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const SmallRollup = (props) => {
  const { eventID, rollups } = props;
  const expenses = useQuery(["expenses-offer-pdf", eventID], () =>
    getEventPDFExpenses(eventID)
  );
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));
  const variables = useQuery(["variables-offer-pdf", eventID], () =>
    getEventOfferVariables(eventID)
  );

  if (expenses.isLoading || event.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  const grossPotential = event.data.manifests.reduce(
    (total, manifest) =>
      total + (manifest.price + event.data.facility_fee) * manifest.qty,
    0
  );
  const ticketFees = event.data.manifests.reduce(
    (total, manifest) => total + manifest.qty * event.data.facility_fee,
    0
  );

  return (
    <table className="w-full border-dashed border-gray-400 border">
      <tbody>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            TOTAL CAPACITY
          </th>
          <td className="bg-white font-extrabold font-montserrat px-1 text-right w-1/2">
            {rollups.data.capacity}
          </td>
        </tr>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            GROSS POTENTIAL
          </th>
          <td className="bg-white font-extrabold font-montserrat px-1 text-right w-1/2">
            {amountDisplay(grossPotential)}
          </td>
        </tr>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            TICKET FEES
          </th>
          <td className="bg-white font-extrabold font-montserrat px-1 text-right w-1/2">
            {amountDisplay(ticketFees)}
          </td>
        </tr>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            ADJUSTED GROSS
          </th>
          <td className="bg-white font-extrabold font-montserrat px-1 text-right w-1/2">
            {amountDisplay(variables.data.adjusted_gross)}
          </td>
        </tr>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            TAX PERCENTAGE
          </th>
          <td className="bg-gray-300 font-extrabold font-montserrat px-1 text-right w-1/2">
            {event.data.venue.sales_tax}%
          </td>
        </tr>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            TOTAL TAX
          </th>
          <td className="bg-white font-extrabold font-montserrat px-1 text-right w-1/2">
            {amountDisplay(variables.data.total_tax)}
          </td>
        </tr>
        <tr>
          <th className="bg-blue-300 text-left font-extrabold font-montserrat px-1 w-1/2">
            NET POTENTIAL
          </th>
          <td className="bg-white font-extrabold font-montserrat px-1 text-right w-1/2">
            {amountDisplay(variables.data.net_potential)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const MiddleRow = (props) => {
  const { eventID } = props;
  const event = useQuery(["event-detail", eventID], () => getEvent(eventID));
  const variables = useQuery(["variables-offer-pdf", eventID], () =>
    getEventOfferVariables(eventID)
  );

  if (event.isLoading || variables.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  // Promoter Profit is default here
  let leftColumn = <SplitPoint eventID={eventID} />;

  // If it's a VS deal, we show different info
  if (event.data.offer_type == "vs") {
    leftColumn = (
      <div className="border border-slate-300 w-full">
        <div className="w-full bg-gray-300 text-center text-sm font-extrabold font-montserrat">
          VERSUS {event.data.offer_percentage}% DEAL
        </div>
        <div className="w-full flex justify-between p-1 text-sm">
          <span className="font-montserrat">TOTAL EXPENSES</span>
          <span className="font-montserrat">
            {amountDisplay(variables.data.total_expenses)}
          </span>
        </div>
        <div className="w-full flex justify-between p-1 text-sm">
          <span className="font-montserrat">LESS GUARANTEE</span>
          <span className="font-montserrat">
            {amountDisplay(variables.data.the_deal_amount)}
          </span>
        </div>
        <div className="w-full flex justify-between p-1 text-sm">
          <span className="font-extrabold font-montserrat">NET EXPENSES</span>
          <span className="font-montserrat">
            {amountDisplay(variables.data.net_expenses)}
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-1/2 mr-4">{leftColumn}</div>

      <div className="w-1/2 mr-4">
        <div className="border border-slate-300 w-full">
          <div className="w-full bg-gray-300 text-center text-sm font-extrabold font-montserrat">
            WALK-OUT POTENTIAL
          </div>
          <div className="w-full flex p-1 text-sm text-right">
            <span className="w-3/4 border-r border-slate-400 pr-4">
              {amountDisplay(variables.data.walkout_potential)}
            </span>
            <span className="w-1/4 font-montserrat">
              {variables.data.walkout_percentage}%
            </span>
          </div>
          <div className="w-full bg-gray-300 text-center text-sm font-extrabold font-montserrat">
            DEPOSITS
          </div>
          <div className="w-full flex p-1 text-xs">
            <p className="my-1 text-center w-full font-montserrat">
              10%, 10 DAYS OUT. NO DEPOSITS UNDER $500 OR FOR SUPPORT.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const EventOfferPDF = (props) => {
  const { id } = useParams();
  const event = useQuery(["event-detail", id], () => getEvent(id));
  const settlement = useQuery(["event-settlement-info", id], () =>
    getEventSettlementInfo(id)
  );
  const people = useQuery(["event", "people", id], () => getEventPeople(id));
  const rollups = useQuery(["event-rollups", id], () => getEventRollups(id));
  const variables = useQuery(["variables-offer-pdf", id], () =>
    getEventOfferVariables(id)
  );

  if (
    event.isLoading ||
    people.isLoading ||
    rollups.isLoading ||
    settlement.isLoading ||
    variables.isLoading
  ) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }

  const handleMapOptions = (options) => {
    return options.map((option, index) => {
      return { name: option?.name, id: option?.id };
    });
  };
  const artists_list = handleMapOptions(event.data.artists);
  // Determine merch percentages
  let mammothSoftMerch = 100 - event.data.artist_soft_merch_percentage;
  let mammothHardMerch = 100 - event.data.artist_hard_merch_percentage;

  return (
      <>
      <div className="ml-8 mr-8 mt-16 bm-8">
    <div className="p-2 print-page">
      <div className="w-full flex bg-white">
        <div className="flex flex-col w-1/4 p-8 justify-center content-center">
          <MammothLogo className="place-self-center" width={300} height={125} />
          <div className="text-center ml-6 mt-3 text-sm">
            1520 HASKELL AVE LAWRENCE, KS 66044
            <br />
            785.749.7475 PH | 785.865.4110 FX
          </div>
        </div>
        <div className="w-3/4 flex">
          <div className="w-1/2">
            <table className="w-full text-sm border-dashed border-gray-400 border ">
              <tbody>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    AGENT
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_agent}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    BUYER
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {variables.data.buyer_name}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    HEADLINER{artists_list.length > 1 ? "S" : ""}
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {artists_list.map((artist) => (
                      <span>&nbsp;{artist.name}&nbsp;</span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    SUPPORT
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_support_1}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    SUPPORT
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_support_2}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    OPENER
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_opener}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    BILLING
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_billing}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    STIPULATIONS
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_stipulations}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    AVAILS
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_avails_1}
                  </td>
                </tr>
                <tr>
                  <th className="bg-white font-montserrat font-extrabold text-left w-1/3 px-1">
                    AVAILS
                  </th>
                  <td className="bg-blue-300 font-montserrat w-1/2 p-1">
                    {event.data.offer_avails_2}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2 bg-white text-center font-semibold ">
            <table className="w-full  bg-white text-center">
              <thead>
                <tr>
                  <th
                    className="w-full bg-gray-300 font-montserrat font-bold border-dashed border-gray-400 border"
                    colSpan="2"
                  >
                    VENUE INFORMATION
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-blue-300">
                  <td className="font-montserrat font-semibold">
                    {event.data.venue.name || <>&nbsp;</>}
                  </td>
                </tr>
                <tr className="bg-blue-300">
                  <td className="font-montserrat font-semibold">
                    {event.data.venue.address || <>&nbsp;</>}
                  </td>
                </tr>
                <tr className="bg-blue-300">
                  <td className="font-montserrat font-semibold">
                    {event.data.venue.city}, {event.data.venue.state}{" "}
                    {event.data.venue.zipcode}{" "}
                    {!event.data.venue.zipcode &&
                      !event.data.venue.state &&
                      !event.data.venue.city && <>&nbsp;</>}
                  </td>
                </tr>
                <tr className="bg-blue-300">
                  <td className="font-montserrat font-semibold">
                    {event.data.venue.phone || <>&nbsp;</>}
                  </td>
                </tr>
                <tr className="bg-blue-300">
                  <td className="font-montserrat font-semibold">
                    {event.data.venue.url || <>&nbsp;</>}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="w-full">
              <table className="w-full">
                <tbody>
                  <tr className="border-dashed border-gray-400 border">
                    <th className="bg-gray-300 text-right font-montserrat font-extrabold pr-2">
                      AGE REQUIREMENT
                    </th>
                    <td className="bg-blue-300 font-montserrat font-extrabold">
                      {event.data.age_requirement}
                    </td>
                  </tr>
                  <tr>
                    <th className="bg-white text-right font-montserrat font-semibold pr-2">
                      SOFT MERCH
                    </th>
                    <td className="bg-blue-300 font-montserrat font-extrabold">
                      {event.data.artist_soft_merch_percentage}/
                      {mammothSoftMerch}
                    </td>
                  </tr>
                  <tr>
                    <th className="bg-white text-right font-montserrat font-semibold pr-2">
                      HARD MERCH
                    </th>
                    <td className="bg-blue-300 font-montserrat font-extrabold">
                      {event.data.artist_hard_merch_percentage}/
                      {mammothHardMerch}
                    </td>
                  </tr>
                  <tr>
                    <th className="bg-white text-right font-montserrat font-semibold pr-2">
                      WHO SELLS
                    </th>
                    <td className="bg-blue-300 font-montserrat font-extrabold">
                      {event.data.who_sells_merch}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex bg-white mt-4">
        <div className="w-1/4 mr-4">
          <Expenses eventID={id} />
        </div>
        <div className="w-3/4">
          <Tickets
            manifests={event.data.manifests.filter((m) => m.active === true)}
            ticketFees={event.data.facility_fee}
          />

          <div className="w-full flex mt-6">
            <div className="w-1/2 text-sm mr-6">
              <SmallRollup eventID={id} rollups={rollups} />
            </div>
            <div className="w-1/2">
              <TheDeal eventID={id} />
            </div>
          </div>

          <div className="w-full flex mt-6">
            <MiddleRow eventID={id} />
          </div>

          <div className="w-full flex-col mt-6 border border-slate-400">
            <div className="w-full bg-gray-300 text-center p-2 text-xl font-extrabold font-montserrat">
              CONTACT INFO
            </div>

            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">
                PRODUCTION ADV.
              </span>
              <span className="text-sm font-montserrat w-2/3">
                production@mammothlive.com
              </span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">
                LOCAL MARKETING
              </span>
              <span className="text-sm font-montserrat w-2/3"></span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">TICKETING</span>
              <span className="text-sm font-montserrat w-2/3">
                ticketing@mammothlive.com
              </span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">TOUR ADMAT</span>
              <span className="text-sm font-montserrat w-2/3"></span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">
                TOUR MARKETING
              </span>
              <span className="text-sm font-montserrat w-2/3"></span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">ONSALE INFO</span>
              <span className="text-sm font-montserrat w-2/3">
                {event.data.offer_onsale_info}
              </span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">
                PRE-SALE INFO
              </span>
              <span className="text-sm font-montserrat w-2/3">
                {event.data.offer_presale_info}
              </span>
            </div>
            <div className="w-full flex p-1">
              <span className="text-sm font-montserrat w-1/3">CONTRACTS</span>
              <span className="text-sm font-montserrat w-2/3">
                contracts@mammothlive.com
              </span>
            </div>
          </div>

          <div className="w-full flex mt-6">
            <div className="w-[42%] ">
              <Variables eventID={id} />
            </div>

            <div className="w-[8%] mr-4">
              <VariablesSubText eventID={id} />
            </div>

            <div className="w-1/2 border-dashed border-gray-400 border">
              <div className="w-full bg-blue-300">
                <div className="w-full bg-gray-300 text-center text-sm font-extrabold font-montserrat">
                  NOTES
                </div>
                <p className="w-full my-4 p-1">{event.data.offer_notes}</p>
                <div className="w-full bg-gray-300 text-center text-sm font-extrabold font-montserrat">
                  TIMES
                </div>
                <div className="w-full bg-blue-300 flex justify-between p-1 text-sm">
                  <span className="font-montserrat">DOORS</span>
                  <span>{localTime(event.data.door_time)}</span>
                </div>
                <div className="w-full bg-blue-300 flex justify-between p-1 text-sm">
                  <span className="font-montserrat">OPENER</span>
                  <span>{localTime(event.data.opener_time)}</span>
                </div>
                <div className="w-full bg-blue-300 flex justify-between p-1 text-sm">
                  <span className="font-montserrat">HEADLINER</span>
                  <span>{localTime(event.data.headliner_time)}</span>
                </div>
                <div className="w-full bg-blue-300 flex justify-between p-1 text-sm">
                  <span className="font-montserrat">CURFEW</span>
                  <span>{localTime(event.data.curfew_time)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-300 p-6 text-sm border-dashed border-gray-400 border">
        <ul className="list-disc ml-4">
          <li className="font-extrabold font-montserrat">
            DEPOSITS: 10%, 10 DAYS OUT. NO DEPOSITS ON SUPPORT OR UNDER $500.
          </li>
          <li className="font-extrabold font-montserrat">
            OFFER EXPIRES 10 DAYS AFTER SUBMITTED.
          </li>
          <li className="font-extrabold font-montserrat">
            EXCLUSIVITY: No other play within 150 miles 120 days before or 90
            days after this event.
          </li>
          <li className="font-extrabold font-montserrat">
            FIRST RIGHT OF REFUSAL: Mammoth shall have a first right of refusal
            on all other dates within 150 miles for 18 months after this event.
          </li>
          <li className="font-extrabold font-montserrat">
            No Artist VIP Lift.
          </li>
          <li className="font-extrabold font-montserrat">
            Capacity, ticket scaling, & ticket price are subject to change based
            on the final line up and production requirements.
          </li>
          <li className="font-montserrat">
            <span className="font-extrabold">
              Mammoth does not return Artist Contracts more than 30 days before
              show.
            </span>{" "}
            To expedite processing, please include Artist's W9 as well as Artist
            Rider(s).
          </li>
          <li className="font-montserrat">
            <span className="font-extrabold">Reduction in Capacity.</span>{" "}
            Governmental reduction in Capacity will equal a Force Majeure
            situation and/or renegotiation of deal.
          </li>
          <li className="font-montserrat">
            <span className="font-extrabold">Stop Loss. </span>
            Artist Guarantee (incl. all talent and artist reimbursables) shall
            not exceed 100% of the Net Box Office Revenue (NBOR). If the NBOR is
            lower than the established guarantee then the guarantee will be
            reduced to meet the NBOR. NBOR is defined as gross ticket revenues
            less facility fees and any applicable taxes. Support & Artist
            reimbursable expenses are included in guarantee when factoring a
            reduced offer. The deal as “all in”.
          </li>
          <li className="font-montserrat">
            <span className="font-extrabold">FORCE MAJEURE:</span> In the event
            of cancellation due to a Force Majeure the respective parties'
            obligations will be fully excused, there shall be no claim for
            damages or expenses by either party against the other and each party
            shall bear their own costs and expenses in connection therewith.
          </li>
          <li className="font-montserrat">
            <span className="font-extrabold">INCLEMENT WEATHER:</span> Should
            Inclement Weather disrupt essential services, create dangerous
            conditions and/or threaten the safety of artist(s) or attendees,
            then such conditions shall be deemed a Force Majeure occurrence.
          </li>
        </ul>
      </div>
    </div>
      </div>
      </>
  );
};

export default EventOfferPDF;
