import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import EventState from "@/components/EventState";

const EventRow = (props) => {
  const { event } = props;
  const navigate = useNavigate();
  const eventDate = dayjs(event.date);

  const goToEvent = () => {
    navigate(`/events/${event.id}`);
  };
  return (
    <tr
      onClick={goToEvent}
      className="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {event.name}
      </th>
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        <EventState state={event.state} />
      </th>
      <td className="cursor-pointer px-6 py-4">
        {event.venue.name} - {event.venue.city}, {event.venue.state}
      </td>
      <td className="cursor-pointer px-6 py-4">
        {eventDate.format("MMMM D, YYYY")}
      </td>
      <td className="px-6 py-4">
  <span className="cursor-pointer text-gray-400">{event.tickets_sold} / {event.tickets_total}</span> (
    {
      event.tickets_sold > 0
        ? <span className="text-gray-700">{((event.tickets_sold / event.tickets_total) * 100).toFixed(2)}%</span>
        : <span className="text-gray-700">100%</span>
    }
  )
</td>

    </tr>
  );
};

export const EventTable = (props) => {
  const { events = [], past } = props;

  let emptyText = "No upcoming events found";
  if (past) {
    emptyText = "No past events found";
  }

  return (
    <div className="my-2 w-full overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Event Name
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Venue
            </th>
            <th scope="col" className="px-6 py-3">
              Date
            </th>
            <th scope="col" className="px-6 py-3">
              Sold / Available
            </th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <EventRow key={event.id} event={event} />
          ))}
          {!events.length && (
            <tr>
              <td colSpan={5} className="cursor-pointer p-4 text-center">
                <em>{emptyText}</em>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
