import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { Header } from "@/components/Header";
import { TemplateManager } from "@/components/TemplateManager";
import {
  getEvent,
  updateEventOffer,
  getEventOfferVariables,
  updateEvent,
  updateManifest,
  updateEventAccounting,
  getEventState,
  postEventState,
} from "@/queries/events";
import {
  getEventRollups,
  getEventPDFExpenses,
  updateEventExpense,
  deleteExpense,
} from "@/queries/accounting";
import { getVenue } from "@/queries/venues";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  amountDisplay,
  amountStrToInt,
  formatInputToMoney,
} from "@/utils/money";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { localTime, formatTimeString, formatToHHMMSS } from "@/utils/datetime";
import { selectValueToOption } from "@/components/Form";
import Select, { components } from "react-select";
import {
  addEventToOffer,
  createEventManifest,
  createEventPDF,
  createOffer,
  deleteEventManifest,
  getEventPDFs,
  getOffer,
  getOffers,
  updateOffer,
} from "../../queries/events";
import { usePageTitle } from "@/utils/pagetitle";
import { ToggleSwitch } from "../../components/Toggle/Toggle";
import { getVenues, updateVenue } from "../../queries/venues";
import Button from "@/components/Button";
import { WalkoutPot } from "@/components/WalkoutPot";
import { createEventExpense, getExpenseGroups } from "../../queries/accounting";
import { StateSwitcher } from "@/components/EventState/StateSwitcher.jsx";
import { useUser, usePerms } from "@/components/Auth";
import { DateChanger } from "@/components/EventState/DateChanger";
import { getArtists } from "../../queries/artists";
import { DataGrid } from "@/components/DataGrid";
import { toast } from "react-toastify";

export const FIELD_UPDATE_ANIMATION_TIME = 2000;

const DealTable = ({ dealAmount, dealDescription, isChanged }) => {
  return (
    <div className="min-w-full border border-gray-300">
      <div className="bg-white flex justify-between items-center">
        <span className="bg-gray-300 dark:bg-gray-100 p-2 h-full w-1/3 text-left font-bold text-xl pl-8 font-montserrat">
          THE DEAL
        </span>
        <span
          className={`font-bold w-2/3 h-full text-2xl text-end p-2 font-montserrat ${isChanged ? "glow-text" : ""}`}
        >
          {dealAmount}
        </span>
      </div>
      <textarea
        className="text-black text-sm w-full p-2 text-center border-t border-gray-300 dark:bg-gray-100 font-montserrat"
        style={{ minHeight: "3rem" }}
        value={dealDescription}
        disabled
      />
    </div>
  );
};

const TextAreaTable = ({
  header,
  noBlue,
  value = "",
  disabled,
  onInputChange,
  accessor,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  return (
    <div className="flex flex-col h-full w-full">
      <table className="w-full h-full">
        <thead className="bg-gray-300">
          <tr>
            <th className="text-sm text-center py-1 px-2 font-montserrat">
              {header}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="h-full">
            <td className="h-full">
              <textarea
                className={`text-center text-sm w-full h-full font-montserrat ${noBlue ? "bg-white" : "bg-blue-300"} selection:bg-blue-900 selection:text-white p-1 focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none`}
                className={classNames(
                  !isAdding
                    ? `text-center text-sm w-full h-full font-montserrat ${noBlue ? "bg-white" : "bg-blue-300"} selection:bg-blue-900 selection:text-white p-1 focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none`
                    : "bg-cave-blue-1 border-cave-blue-3 border-4 p-4 m-2"
                )}
                defaultValue={value}
                disabled={disabled}
                onBlur={(e) =>
                  onInputChange && onInputChange(accessor, e.target.value)
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Define the ITEM_TYPE for react-dnd
const ITEM_TYPE = "ROW";

// Helper function to format input titles
const formatInput = (input) => input.toLowerCase().replace(/\s+/g, "_");

// MultiLineTableCell Component (as provided)
const MultiLineTableCell = ({
  header,
  isGreyedOut,
  handleKeyDown,
  handleFocus,
  handleBlur,
  children,
  placeholder,
  isDisabled = false,
  isChanged = false,
}) => {
  const getEditable = () => {
    if (header.editable === false || isGreyedOut || isDisabled) return false;
    return true;
  };

  return (
    <td className="relative" disabled>
      <div
        className={`relative min-w-full max-w-[145px] h-[28px] cell-content p-1 text-sm font-montserrat whitespace-nowrap overflow-hidden text-ellipsis focus:overflow-visible focus:absolute focus:z-10 focus:bg-gray-100 focus:max-w-none ${
          isChanged ? "glow-text" : ""
        }`}
        contentEditable={getEditable()}
        suppressContentEditableWarning={true}
        tabIndex={0}
        onKeyDown={(e) => handleKeyDown && handleKeyDown(e)}
        onFocus={(e) => handleFocus && handleFocus(e)}
        onBlur={(e) => handleBlur && handleBlur(e)}
        style={{ opacity: isGreyedOut ? 0.2 : 1 }}
        data-placeholder={children === "" || !children ? placeholder : ""}
      >
        {children}
      </div>
    </td>
  );
};

const MultiLineManifestTable = ({
  rows: rowsProp,
  event,
  eventId,
  headers,
  onInputChange,
  onDeletePress,
  addRowButtonName,
  onAddNewRow,
  hasChangeDetection,
}) => {
  const queryClient = useQueryClient(); // Initialize React Query's queryClient
  const [rows, setRows] = useState([]);
  const [deletingRowId, setDeletingRowId] = useState(null);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const TEMP_ROW_PLACEHOLDERS = {
    ticketing: "New Manifest",
    capacity: 0,
    net_price: 0,
    ticket_fees: 0,
    gross_price: "n/a",
    potential: "n/a",
  };
  const TEMP_ROW = {
    ticketing: "",
    capacity: "",
    net_price: "",
    ticket_fees: "",
    gross_price: "n/a",
    potential: "n/a",
  };
  const [tempRow, setTempRow] = useState(TEMP_ROW);
  const [changedCells, setChangedCells] = useState([]);

  const prevRowsRef = useRef(rowsProp);
  const originalContentRef = useRef("");
  const lastFocusedElementRef = useRef(null);

  // Initialize rows state when rowsProp changes
  useEffect(() => {
    if (rowsProp && rowsProp.length > 0) {
      const initializedRows = rowsProp.map((row, index) => ({
        ...row,
        sort_order: row.sort_order || index + 1,
        id: row.id || `row-${index + 1}`, // Ensure unique id
        eventId: row.eventId,
        manifest_id: row.manifest_id,
        name: row.name,
        qty: row.qty,
        price: row.price,
        on_sale: row.on_sale,
        comps: row.comps,
        ticket_fees: row.ticket_fees,
        kills: row.kills,
        is_offer: row.is_offer,
      }));
      setRows(initializedRows.sort((a, b) => a.sort_order - b.sort_order));
    }
  }, [rowsProp]);

  // Update prevRowsRef whenever rows change
  useEffect(() => {
    prevRowsRef.current = rows;
  }, [rows]);

  // Change detection effect
  useEffect(() => {
    if (!hasChangeDetection) return;
    const newChanges = [];
    rows.forEach((row, rowIndex) => {
      Object.keys(row).forEach((key) => {
        if (row[key] !== prevRowsRef.current[rowIndex][key]) {
          newChanges.push(`${rowIndex}-${key}`);
        }
      });
    });
    if (newChanges.length > 0) {
      setChangedCells(newChanges);
      prevRowsRef.current = rows;
      setTimeout(() => {
        setChangedCells([]);
      }, 1000); // Adjust FIELD_UPDATE_ANIMATION_TIME as needed
    }
  }, [rows, hasChangeDetection]);

  const updateEventManifest = useMutation({
    mutationFn: async (data) => {
      const { kills, is_offer, manifest_id, sort_order } = data;

      const updateData = {
        ...data,
        sort_order: sort_order,
        kills: 0,
        is_offer: true,
      };
      return await updateManifest(updateData.manifest_id, updateData);
    },
    onError: async (err, newManifestData, context) => {
      const id = newManifestData?.eventId;
      toast.error("Error updating manifest");
      await event?.refetch();
    },
    onSuccess: async (newManifestData) => {
      const id = newManifestData?.eventId;
      // await event?.refetch();
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
  });

  // Function to handle sort order changes
  const handleSortOrderChange = async (reorderedRows) => {
    try {
      // Map each manifest to a mutateAsync call
      const updatePromises = reorderedRows.map((row) => {
        updateEventManifest.mutateAsync({
          manifest_id: row.id,
          sort_order: row.sort_order,
          eventId: row.eventId,
          name: row.name,
          qty: row.qty,
          price: row.price,
          on_sale: row.on_sale,
          comps: row.comps,
          ticket_fees: row.ticket_fees,
        });
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      // Show success message
      toast.success("Sort order updated successfully");
    } catch (error) {
      // Errors are already handled in the mutation's onError
      console.error("Failed to update sort order:", error);
    }
  };

  // Function to handle moving a row in the table
  const moveRowHandler = (fromIndex, toIndex) => {
    const updatedRows = [...rows];
    const [movedRow] = updatedRows.splice(fromIndex, 1);
    updatedRows.splice(toIndex, 0, movedRow);

    const reorderedRows = updatedRows.map((row, index) => {
      return {
        ...row,
        id: row.id,
        eventId: eventId, // Include eventId for the mutation
        sort_order: index + 1,
        on_sale: new Intl.DateTimeFormat("en-CA").format(new Date(row.on_sale)),
      };
    });
    // Update the local state with the new order
    setRows(updatedRows);

    // Trigger the mutation to save the new order to the backend
    handleSortOrderChange(reorderedRows);
  };

  // DraggableRow Component
  const DraggableRow = ({ row, index, moveRow, children }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: ITEM_TYPE,
      hover(item, monitor) {
        if (!ref.current) return;

        const dragIndex = item.index;
        const hoverIndex = index;

        // Do nothing if the item is hovering over itself
        if (dragIndex === hoverIndex) return;

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the item's height
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveRow(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
      drop(item, monitor) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex !== hoverIndex) {
          moveRow(dragIndex, hoverIndex);
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: ITEM_TYPE,
      item: { type: ITEM_TYPE, id: row.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`grid grid-cols-[200px_repeat(6,1fr)_125px]`}
      >
        {children}
      </tr>
    );
  };

  // Delete Handlers
  const handleDeleteInitiate = (rowId) => {
    setDeletingRowId(rowId);
  };

  const handleDeleteConfirm = (rowId) => {
    onDeletePress(rowId);
    setDeletingRowId(null);
  };

  const handleDeleteCancel = () => {
    setDeletingRowId(null);
  };

  // Key Down Handler
  const handleKeyDown = (e, rowIndex, colIndex) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        e.target.blur();
      default:
        return;
    }
  };

  // Add Row Handlers
  const handleAddRow = () => {
    setIsAddingRow(true);
    setTempRow(TEMP_ROW);
  };

  const handleSaveNewManifest = () => {
    let rowData = {
      name:
        tempRow.ticketing === ""
          ? TEMP_ROW_PLACEHOLDERS.ticketing
          : tempRow.ticketing,
      qty:
        tempRow.capacity === ""
          ? TEMP_ROW_PLACEHOLDERS.capacity
          : tempRow.capacity,
      price:
        tempRow.net_price === ""
          ? TEMP_ROW_PLACEHOLDERS.net_price
          : tempRow.net_price,
      ticket_fees:
        tempRow.ticket_fees === ""
          ? TEMP_ROW_PLACEHOLDERS.ticket_fees
          : tempRow.ticket_fees,
      sort_order:
        tempRow.sort_order === ""
          ? TEMP_ROW_PLACEHOLDERS.sort_order
          : tempRow.sort_order,
      eventId:
        tempRow.eventId === ""
          ? TEMP_ROW_PLACEHOLDERS.eventId
          : tempRow.eventId,
      // Include other necessary fields if required
    };
    setIsAddingRow(false);
    setTempRow(TEMP_ROW);
    onAddNewRow && onAddNewRow(rowData);
  };

  const handleCancelNewManifest = () => {
    setIsAddingRow(false);
    setTempRow(TEMP_ROW);
  };

  // Blur and Focus Handlers
  const handleBlur = (e, colIndex, row, rowIndex) => {
    const currentContent = e.target.textContent;

    if (headers[colIndex].format === "numbers") {
      e.target.textContent =
        e.target.textContent === ""
          ? "0"
          : e.target.textContent.replace(/\D/g, "");
      if (e.target.textContent === "0") return;
    } else if (headers[colIndex].format === "money") {
      e.target.textContent = formatInputToMoney(
        e.target.textContent === "" ? "0" : e.target.textContent
      );
      if (
        e.target.textContent === "Invalid Input" ||
        e.target.textContent === "$0.00"
      )
        return;
    }

    // Don't make requests on each blur, only if the text content was changed
    if (!e.target.textContent || currentContent === originalContentRef.current)
      return;

    onInputChange &&
      onInputChange(
        headers[colIndex].accessor,
        e.target.textContent,
        rowIndex,
        row,
        () => {
          lastFocusedElementRef.current?.focus();
        }
      );
  };

  const handleFocus = (e, colIndex) => {
    if (
      headers[colIndex].format === "money" &&
      e.target.textContent === "$0.00"
    ) {
      e.target.textContent = "";
      return;
    } else if (
      headers[colIndex].format === "numbers" &&
      e.target.textContent === "0"
    ) {
      e.target.textContent = "";
      return;
    }
    if (!(headers[colIndex].editable === false)) {
      originalContentRef.current = e.target.textContent;
    }
  };

  // Function to format input as money
  function formatInputToMoney(input) {
    const number = parseFloat(input.replace(/[^0-9.-]+/g, ""));
    if (isNaN(number)) return "Invalid Input";
    return `$${number.toFixed(2)}`;
  }

  // Function to update new cell data when adding a row
  const handleUpdateNewCell = (e, header) => {
    if (header.title === "GROSS PRICE" || header.title === "POTENTIAL") return;
    if (header.format === "money" && e.target.innerText !== "") {
      e.target.innerText = formatInputToMoney(e.target.innerText);
    } else if (header.format === "numbers") {
      e.target.innerText = e.target.innerText.replace(/\D/g, "");
    }
    setTempRow((prev) => ({
      ...prev,
      [formatInput(header.title)]: e.target.innerText,
    }));
  };

  // Ensure that rows and headers are available
  if (!rows || !headers) return <></>;

  return (
    <>
      <table className="w-full grid border border-dashed border-gray-300 bg-gray-100">
        <thead>
          <tr
            className={`grid grid-cols-[200px_repeat(6,1fr)_125px] bg-gray-300`}
          >
            {headers.map((column, index) => (
              <th
                key={index}
                className="text-left px-1 text-sm font-montserrat"
              >
                {column.title}
              </th>
            ))}
            <th key={7} className="text-left px-1 text-sm font-montserrat"></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <DraggableRow
              key={row.id}
              row={row}
              index={index}
              moveRow={moveRowHandler}
            >
              {headers.map((header, colIndex) => (
                <MultiLineTableCell
                  key={colIndex}
                  header={header}
                  isGreyedOut={deletingRowId === row.id}
                  handleKeyDown={(e) => handleKeyDown(e, index, colIndex)}
                  handleFocus={(e) => handleFocus(e, colIndex)}
                  handleBlur={(e) => handleBlur(e, colIndex, row, index)}
                  isChanged={changedCells.includes(
                    `${index}-${formatInput(header.title)}`
                  )}
                >
                  {row[formatInput(header.title)]}
                </MultiLineTableCell>
              ))}

              <td className="flex justify-end gap-1 p-1 text-sm font-montserrat">
                <Bars3Icon className="cursor-pointer w-4 h-4" />
                &nbsp;&nbsp;&nbsp;
                {deletingRowId === row.id ? (
                  <>
                    <button
                      className="bg-red-400 hover:bg-red-600 text-white py-1 px-2 text-xs rounded"
                      onClick={() => handleDeleteConfirm(row.id)}
                    >
                      Confirm
                    </button>
                    <button
                      className="bg-gray-400 hover:bg-gray-600 text-white py-1 px-2 text-xs rounded"
                      onClick={handleDeleteCancel}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="bg-red-400 hover:bg-red-600 text-white py-1 px-2 text-xs rounded"
                    onClick={() => handleDeleteInitiate(row.id)}
                  >
                    Delete
                  </button>
                )}
              </td>
            </DraggableRow>
          ))}

          {isAddingRow && (
            <tr className={`grid grid-cols-[200px_repeat(6,1fr)_125px]`}>
              {headers.map((header, colIndex) => (
                <MultiLineTableCell
                  key={colIndex}
                  header={header}
                  placeholder={TEMP_ROW_PLACEHOLDERS[formatInput(header.title)]}
                  handleBlur={(e) => handleUpdateNewCell(e, header)}
                  handleKeyDown={(e) => handleKeyDown(e, rows.length, colIndex)}
                >
                  {tempRow[formatInput(header.title)]}
                </MultiLineTableCell>
              ))}
              <td className="flex justify-end gap-1 p-1 text-sm font-montserrat">
                <button
                  className="bg-gray-400 hover:bg-gray-500 text-white py-1 px-2 text-xs rounded"
                  onClick={handleSaveNewManifest}
                >
                  Save
                </button>
                <button
                  className="bg-gray-400 hover:bg-gray-500 text-white py-1 px-2 text-xs rounded"
                  onClick={handleCancelNewManifest}
                >
                  Cancel
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {addRowButtonName && (
        <button
          disabled={isAddingRow}
          className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-white font-sans text-sm mt-2"
          onClick={!isAddingRow ? handleAddRow : () => {}}
        >
          {addRowButtonName}
        </button>
      )}
      <div className="pt-2">{/* <TemplateManager />*/}</div>
    </>
  );
};

// DraggableRow Component (defined outside for clarity)
const DraggableRow = ({ row, index, moveRow, children }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { type: ITEM_TYPE, id: row.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={`grid grid-cols-[200px_repeat(6,1fr)_125px]`}
    >
      {children}
    </tr>
  );
};

const EditableTableSelect = ({
  options,
  onSelect,
  parentSelection,
  placeholder,
  isMulti = false,
  customStyles,
}) => {
  const [selectedOption, setSelectedOption] = useState(parentSelection);

  useEffect(() => {
    setSelectedOption(parentSelection);
  }, [parentSelection]);

  const handleChange = (option) => {
    setSelectedOption(option);
    onSelect && onSelect(option);
  };

  const CustomMultiValueRemove = (props) => {
    return selectedOption.length > 1 ? (
      <components.MultiValueRemove {...props} />
    ) : null;
  };

  return (
    <div className="relative z-20">
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti={isMulti}
        isClearable={false}
        components={{
          MultiValueRemove: CustomMultiValueRemove,
        }}
        classNames={{
          container: (state) =>
            `bg-cave-blue-2 border-4 border-cave-blue-3 m-0 cursor-pointer ${customStyles?.container || ""}`,
          control: (state) =>
            `bg-cave-blue-2 p-0 flex justify-center items-center m-0 border-none shadow-none cursor-pointer hover:bg-cave-orange ${customStyles?.control || ""}`,
          valueContainer: (state) =>
            `p-0 bg-cave-blue-2 border-none px-2 text-sm cursor-pointer hover:bg-cave-orange ${customStyles?.valueContainer || ""}`,
          dropdownIndicator: (state) =>
            "text-black border-none text-sm cursor-pointer",
          indicatorSeparator: (state) => "bg-transparent border-none",
          option: (state) =>
            `text-sm cursor-pointer ${state.isFocused ? "bg-gray-100" : "bg-white"} hover:bg-gray-200 ${customStyles?.option || ""}`,
          menu: (state) =>
            "bg-white border border-gray-300 mt-1 rounded-md shadow-lg",
        }}
        unstyled
      />
    </div>
  );
};

const EditableTable = ({
  rows,
  header,
  solidBorder,
  noBlue,
  onInputChange,
  onHeaderChange,
  onMsgChange,
  event,
}) => {
  const [isLoading, setLoading] = useState(null);
  const originalContentRef = useRef(null);

  const [isAdding, setAdding] = useState(null);
  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: (id) => {
      console.log("---> deleting expense");
      return deleteExpense(id);
    },
    onSuccess: async (id) => {
      queryClient.invalidateQueries(["event-detail", id.event]);
      queryClient.invalidateQueries(["variables-offer-pdf", id.event]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id.event]);
    },
  });

  const handleDeleteClick = async (row) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${row.header} expense?`
    );
    if (confirmDelete) {
      // Call the backend mutation for deletion
      //deleteExpense(row.accessor);
      await deleteMutation.mutateAsync(row.otherParams.id);
      if (event)
        queryClient.refetchQueries(["event-offer-pdf"], event?.data?.id);
    }
  };

  const handleBlur = (index, e, row, header) => {
    let valueToSend;

    if (row.isCustom) {
      valueToSend = e.target.textContent;
      e.target.textContent = row.custom_format;
    } else if (row.format === "money") {
      e.target.textContent = formatInputToMoney(
        e.target.textContent === "" ? "0" : e.target.textContent
      );
    } else if (row.format === "time") {
      e.target.textContent = formatTimeString(e.target.textContent);
    } else if (row.format === "number" && e.target.textContent === "") {
      e.target.textContent = "0";
      return;
    }

    if (
      (row.isCustom && originalContentRef.current !== valueToSend) ||
      originalContentRef.current !== e.target.textContent
    ) {
      setLoading(loadingEqn(row, index, header));
      onInputChange &&
        onInputChange(
          row.accessor,
          valueToSend ? valueToSend : e.target.textContent,
          row.otherParams,
          () => {
            setTimeout(() => {
              setLoading(null);
            }, FIELD_UPDATE_ANIMATION_TIME);
          }
        );
    }
  };

  const handleHeaderBlur = (index, e, row) => {
    onHeaderChange &&
      onHeaderChange(row.accessor, e.target.textContent, row.otherParams);
  };

  const handleSelection = (accessor, value, otherParams, callback) => {
    setLoading(true);
    onInputChange && onInputChange(accessor, value, otherParams, callback);
  };

  const handleHeaderSelection = (accessor, value, otherParams) => {
    onHeaderChange && onHeaderChange(accessor, value, otherParams);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    } else if (e.key === "ArrowUp" && index > 0) {
      e.preventDefault();
      const previousRow =
        e.target.parentElement.previousElementSibling.lastElementChild;
      previousRow.focus();
    } else if (e.key === "ArrowDown" && index < rows.length - 1) {
      e.preventDefault();
      const nextRow =
        e.target.parentElement.nextElementSibling.lastElementChild;
      nextRow.focus();
    }
  };

  const handleFocus = (e, row, index) => {
    originalContentRef.current = e.target.textContent;

    e.target.style.outline = "outline 2px solid #3182c";
    e.target.style.zIndex = 10;
    e.target.style.position = "relative";
    if (row.isCustom) {
      e.target.textContent = row.value;
    }
    let shouldRefocus = false;
    if (row.format === "money" && e.target.textContent === "$0.00") {
      e.target.textContent = "";
      shouldRefocus = true;
    } else if (row.format === "number" && e.target.textContent === "0") {
      e.target.textContent = "";
      shouldRefocus = true;
    }

    if (shouldRefocus) {
      requestAnimationFrame(() => {
        e.target.focus();
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(e.target, 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    }
  };

  const loadingEqn = (row, index, header) => {
    return row.header + index + header?.title;
  };

  const handleFormatValue = (value, row) => {
    if (row.isCustom) {
      return row.custom_format;
    } else {
      return value;
    }
  };

  if (!rows) return <></>;

  return (
    <table
      className={`min-w-full border border-gray-300 ${!solidBorder && "border-dashed"}`}
    >
      <thead>
        {header && (
          <tr>
            <th
              className={`bg-gray-300 ${header.value ? "text-left" : "text-center"} font-bold text-sm font-montserrat`}
              colSpan={header.value ? 1 : 2} // Span the entire width if header.value is not provided
            >
              {header.title}
            </th>
            {header.value && (
              <th
                className={`bg-gray-300 text-sm text-right font-montserrat ${header.isChanged ? "glow-text" : ""}`}
              >
                {header.value}
              </th>
            )}
          </tr>
        )}
      </thead>
      <tbody className="w-full">
        {rows.map((row, index) => {
          if (row.highlight) row.editable = false;
          return (
            <tr
              className={`relative ${row.highlight ? "bg-gray-300" : "bg-transparent"}  ${row.isChanged ? "glow-text" : ""}   `}
              key={index}
            >
              <th
                className={`dark:bg-white w-1/3 font-montserrat ${row.highlight ? "font-extrabold" : "font-normal"} text-left p-1 text-sm `}
                data-placeholder={
                  row.placeholder && (row.header === "" || !row.header)
                    ? row.placeholder
                    : ""
                }
                contentEditable={row.isNew}
                suppressContentEditableWarning={true}
                tabIndex={0}
                onBlur={(e) =>
                  row.headerFormat !== "select" &&
                  handleHeaderBlur(index, e, row)
                }
              >
                {(row?.otherParams?.group_name === "Expenses" ||
                  row?.otherParams?.group_name === "Advertising" ||
                  row?.otherParams?.group_name === "Artist" ||
                  row?.otherParams?.group_name === "Catering") && (
                  <div
                    className="inline-block w-4 h-4 bg-red-200 text-black text-xs font-bold flex items-center justify-center cursor-pointer"
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      left: "-15px",
                    }}
                    onClick={() => handleDeleteClick(row)}
                  >
                    X
                  </div>
                )}
                {row.onToggle ? (
                  <div className="flex items-center justify-between gap-2">
                    {row.header}
                    <ToggleSwitch
                      isOn={row.toggle}
                      size="sm"
                      handleToggle={(state) =>
                        row.onToggle({ state: state, accesor: row.accessor })
                      }
                    />
                  </div>
                ) : row.headerFormat === "select" && row.headerSelectOptions ? (
                  <div className="border-2">
                    <EditableTableSelect
                      options={row.headerSelectOptions}
                      customStyles={{
                        container: row.customStyles?.container,
                        control: row.customStyles?.control,
                        valueContainer: row.customStyles?.valueContainer,
                        option: row.customStyles?.option,
                      }}
                      onSelect={(option) =>
                        handleHeaderSelection(
                          row.accessor,
                          option.value,
                          row.otherParams
                        )
                      }
                      parentSelection={row.value}
                      placeholder="Type Name or Select"
                      isMulti={row.isMulti}
                    />
                  </div>
                ) : (
                  row.header
                )}
              </th>
              <td
                className={`w-1/3 p-1 ${noBlue || row.highlight ? "bg-transparent dark:bg-white" : "bg-blue-300"} selection:bg-blue-900 selection:text-white text-sm font-montserrat ${row.isChanged ? "glow-text" : ""}  ${row.isNew ? "bg-cave-blue-2 border-4 border-cave-blue-3" : ""}      `}
                contentEditable={row.editable !== false}
                suppressContentEditableWarning={true}
                onBlur={(e) =>
                  row.format !== "select" &&
                  row.editable !== false &&
                  handleBlur(index, e, row, header)
                }
                onKeyDown={(e) =>
                  row.editable !== false && handleKeyDown(e, index)
                }
                onFocus={(e) =>
                  row.editable !== false && handleFocus(e, row, index)
                }
                tabIndex={0}
                data-placeholder={
                  row.placeholder && (row.value === "" || !row.value)
                    ? row.placeholder
                    : ""
                }
                disabled={isLoading === loadingEqn(row, index, header)}
              >
                {row.format === "select" && row.options ? (
                  <EditableTableSelect
                    options={row.options}
                    customStyles={row.customStyles}
                    onSelect={(option) =>
                      handleSelection(
                        row.accessor,
                        row.isMulti ? option : option.value,
                        row.otherParams,
                        () => {
                          setLoading(false);
                        }
                      )
                    }
                    parentSelection={row.value}
                    isMulti={row.isMulti}
                  />
                ) : (
                  handleFormatValue(row.value, row)
                )}
              </td>
              {row.msg && (
                <td className="w-1/3 bg-blue-300 z-10 top-0.5 right-0 p-1 text-sm text-right font-montserrat">
                  {row.msg}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const EventManifestTable = ({ event, eventId, handleChangeInput }) => {
  const [updatedProperties, setUpdatedProperties] = useState([]);

  const queryClient = useQueryClient();
  usePageTitle(
    event.isSuccess
      ? `[offer]${event.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  useEffect(() => {
    if (event.data?.manifests) {
      const sortedManifests = event.data.manifests
        .filter((m) => m.active === true && m.is_offer === true)
        .sort((a, b) => a.sort_order - b.sort_order);
      setActiveManifests(sortedManifests);
    }
  }, [event.data]);

  const updateManifestSortOrderMutation = useMutation({
    mutationFn: async (data) => {
      return await updateManifest(data.eventId, data.manifests);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["event-detail", data.eventId]);
      queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
      queryClient.invalidateQueries(["event-rollups", eventId]);
    },
  });

  const [activeManifests, setActiveManifests] = useState(
    event.data?.manifests?.filter(
      (m) => m.active === true && m.is_offer === true
    )
  );

  useEffect(() => {
    setActiveManifests(
      event.data?.manifests?.filter(
        (m) => m.active === true && m.is_offer === true
      )
    );
  }, [event.data]);

  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  const addManifestMutation = useMutation({
    mutationFn: async (newManifest) => {
      // Force this to be is_offer for all Manifests created on this page
      newManifest.is_offer = true;
      newManifest.sort_order = 0;

      return await createEventManifest(eventId, newManifest);
    },
    onSuccess: async (data) => {
      await event?.refetch();
      queryClient.invalidateQueries(["event-detail", eventId]);
      queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
      queryClient.invalidateQueries(["event-rollups", eventId]);
    },
  });

  const removeManifestMutation = useMutation({
    mutationFn: async (manifestId) => {
      const result = await deleteEventManifest(eventId, manifestId);
      return { result, eventId };
    },
    onSuccess: async ({ eventId }) => {
      await event?.refetch();
      queryClient.refetchQueries(["event-detail", eventId]);
      queryClient.invalidateQueries(["event-detail", eventId]);
      queryClient.invalidateQueries(["variables-offer-pdf", eventId]);
      queryClient.invalidateQueries(["event-rollups", eventId]);
    },
  });

  if (event.isLoading)
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (event.isError) return <div>Error loading event data</div>;

  const handleAddManifest = (data) => {
    addManifestMutation.mutate(data);
  };

  const handleRemoveManifest = (manifestId) => {
    removeManifestMutation.mutate(manifestId);
  };

  const rows =
    activeManifests?.map((manifest) => {
      const ticketFees = manifest.ticket_fees || 0;
      return {
        ...manifest,
        ticketing: manifest.name,
        capacity: manifest.qty,
        comps: manifest.comps,
        net_price: amountDisplay(manifest.price),
        ticket_fees: amountDisplay(ticketFees),
        gross_price: amountDisplay(manifest.price + ticketFees),
        potential: amountDisplay((manifest.price + ticketFees) * manifest.qty),
        id: manifest.id,
        sort_order: manifest.sort_order,
        eventId: manifest.eventId,
      };
    }) || [];

  const headers = [
    { title: "TICKETING", accessor: "name" },
    { title: "CAPACITY", format: "numbers", accessor: "qty" },
    { title: "COMPS", format: "numbers", accessor: "comps" },
    { title: "NET PRICE", format: "money", accessor: "price" },
    { title: "TICKET FEES", format: "money", accessor: "ticket_fees" },
    { title: "GROSS PRICE", format: "money", editable: false },
    { title: "POTENTIAL", format: "money", editable: false },
  ];

  return (
    <div>
      <MultiLineManifestTable
        headers={headers}
        rows={rows}
        eventId={eventId}
        event={event}
        onInputChange={(accessor, value, rowIndex, row) => {
          handleChangeInput(accessor, value, row, event.data.id);
        }}
        onDeletePress={handleRemoveManifest}
        addRowButtonName="Add Manifest"
        onAddNewRow={handleAddManifest}
        hasChangeDetection={true}
      />
    </div>
  );
};

const ContactTable = ({ eventID, eventData }) => {
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const latestOffer = eventData.offers.sort(
    (a, b) => new Date(b.modified) - new Date(a.modified)
  )[0];

  const offerID = latestOffer ? latestOffer.id : null;

  const queryClient = useQueryClient();
  //const offers = useQueryWithChangeDetection(
  //  ["offers"],
  //  () => getOffers(),
  //  {},
  //  setUpdatedProperties
  //);
  const offer = useQueryWithChangeDetection(
    ["offer-detail", offerID],
    () => getOffer(offerID),
    {
      enabled: !!offerID,
    },
    setUpdatedProperties
  );

  const { user } = useUser();

  const createOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await createOffer(data);
    },
    onSuccess: (data) => {
      setOfferID(data.id);
      let body = {
        event_id: eventID,
        offer_id: data.id,
      };
      addEventToOfferMutation.mutate(body);
    },
  });

  const updateOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await updateOffer(offerID, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["offer-detail", offerID]);
    },
  });

  const addEventToOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await addEventToOffer(parseInt(data.event_id), data.offer_id);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["offer-detail", offerID]);
    },
  });

  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  useEffect(() => {
    //if (offers.isLoading || !offers.data) {
    //  return;
    //}

    //let offerFound = offers.data
    //  .sort((a, b) => a.id - b.id)
    //  .find((offer) => {
    //    return offer.events.includes(parseInt(eventID));
    //  });

    if (latestOffer) {
      queryClient.invalidateQueries(["offer-detail", latestOffer.id]);
    } else {
      createOfferMutation.mutate(getOfferParams(null));
    }
  }, []);

  //if (offers.isLoading) return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  //if (offers.isError) return <div>Error loading Offer Data...</div>;

  function getOfferParams(offerData) {
    return offerData
      ? {
          production_adv: offer.data.production_adv,
          local_marketing: offer.data.local_marketing,
          ticketing: offer.data.ticketing,
          tour_admat: offer.data.tour_admat,
          tour_marketing: offer.data.tour_marketing,
          onsale_info: offer.data.onsale_info,
          presale_info: offer.data.presale_info,
          contracts: offer.data.contracts,
          draft: offer.data.draft,
          name: offer.data.name,
          created: offer.data.created,
          modified: new Date().toISOString(),
          buyer_id: offer.data.buyer_id || user?.data?.id,
          created_by_id: offer.data.created_by_id || user?.data?.id,
          events: offer.data.events || [eventID],
          active: offer.data.active,
          closed: offer.data.closed,
        }
      : {
          production_adv: "production@mammothlive.com",
          local_marketing: "",
          ticketing: "ticketing@mammothlive.com",
          tour_admat: "",
          tour_marketing: "",
          onsale_info: "",
          presale_info: "",
          contracts: "contracts@mammothlive.com",
          draft: false,
          name: "New Offer",
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          buyer_id: user?.data?.id,
          created_by_id: user?.data?.id,
          events: [eventID],
          active: true,
          closed: false,
        };
  }

  function handleUpdateOffer(accessor, value, otherParams, callback) {
    updateOfferMutation.mutate(
      Object.assign(getOfferParams(offer.data), { [accessor]: value }),
      {
        onSuccess: () => {
          callback?.();
        },
      }
    );
  }

  return offer?.data ? (
    <EditableTable
      rows={[
        {
          header: "PRODUCTION ADV.",
          value: offer.data.production_adv || "production@mammothlive.com",
          accessor: "production_adv",
          isChanged: updatedProperties.includes("production_adv"),
        },
        {
          header: "LOCAL MARKETING",
          value: offer.data.local_marketing,
          accessor: "local_marketing",
          isChanged: updatedProperties.includes("local_marketing"),
        },
        {
          header: "TICKETING",
          value: offer.data.ticketing || "ticketing@mammothlive.com",
          accessor: "ticketing",
          isChanged: updatedProperties.includes("ticketing"),
        },
        {
          header: "TOUR ADMAT",
          value: offer.data.tour_admat,
          accessor: "tour_admat",
          isChanged: updatedProperties.includes("tour_admat"),
        },
        {
          header: "TOUR MARKETING",
          value: offer.data.tour_marketing,
          accessor: "tour_marketing",
          isChanged: updatedProperties.includes("tour_marketing"),
        },
        {
          header: "ONSALE INFO",
          value: offer.data.onsale_info,
          accessor: "onsale_info",
          isChanged: updatedProperties.includes("onsale_info"),
        },
        {
          header: "PRE-SALE INFO",
          value: offer.data.presale_info,
          accessor: "presale_info",
          isChanged: updatedProperties.includes("presale_info"),
        },
        {
          header: "CONTRACTS",
          value: offer.data.contracts || "contracts@mammothlive.com",
          accessor: "contracts",
          isChanged: updatedProperties.includes("contracts"),
        },
      ]}
      header={{ title: "CONTACT INFO" }}
      noBlue={true}
      onInputChange={handleUpdateOffer}
    />
  ) : (
    <></>
  );
};

const EditableField = ({ item, onBlur, className }) => {
  const [field, setField] = useState(item.value);
  const [isEditingField, setIsEditingField] = useState(false);
  const [updated, setUpdated] = useState(false);
  const fieldChanged = () => field !== item?.value;

  const handleOnBlur = () => {
    setIsEditingField(false);
    if (onBlur && fieldChanged()) {
      setUpdated(true);
      onBlur(field);
    }
  };

  useEffect(() => {
    if (item?.value) setField(item?.value);
  }, [item]);

  if (updated) {
    setTimeout(() => {
      setUpdated(false);
    }, FIELD_UPDATE_ANIMATION_TIME);
  }
  return (
    <input
      className={`ring ring-transparent hover:ring-blue-400 focus:ring-blue-600 focus:outline-none duration-200 ${className} ${updated ? "glow-text" : ""}`}
      type="text"
      value={field}
      onChange={(e) => setField(e.target.value)}
      onBlur={handleOnBlur}
      onKeyDown={(e) => {
        if (e.key === "Enter" && fieldChanged()) {
          e.target.blur(); // Unfocus input after pressing Enter
          e.preventDefault();
        }
      }}
    />
  );
};

const ExpenseTable = ({
  handleUpdateExpense,
  displayExpenses,
  advertisingExpenses,
  totalAdvertisingExpenses,
  id,
  event,
}) => {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputHeader, setInputHeader] = useState("");
  const [advExpenseUpdate, setAdvExpenseUpdate] = useState(false);
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [notes, setNotes] = useState("");

  const advTotalRef = useRef(totalAdvertisingExpenses);
  const groups = useQuery(["expense-groups"], () => getExpenseGroups());
  const queryClient = useQueryClient();

  const prevDisplayExpensesRef = useRef();
  const prevAdvertisingExpensesRef = useRef();

  useEffect(() => {
    prevDisplayExpensesRef.current = displayExpenses;
    prevAdvertisingExpensesRef.current = advertisingExpenses;
  }, []);

  useEffect(() => {
    const compareArrays = (prevArray, currArray) => {
      const changes = [];
      currArray.forEach((item, index) => {
        const prevItem = prevArray[index];
        if (prevItem && item) {
          Object.keys(item).forEach((key) => {
            if (item[key] !== prevItem[key]) {
              changes.push(item.subgroup_name?.toUpperCase());
            }
          });
        }
      });
      return changes;
    };

    const displayChanges = compareArrays(
      prevDisplayExpensesRef.current,
      displayExpenses
    );
    const advertisingChanges = compareArrays(
      prevAdvertisingExpensesRef.current,
      advertisingExpenses
    );

    setUpdatedProperties([
      ...new Set([...displayChanges, ...advertisingChanges]),
    ]);

    prevDisplayExpensesRef.current = displayExpenses;
    prevAdvertisingExpensesRef.current = advertisingExpenses;
  }, [displayExpenses, advertisingExpenses]);

  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  useEffect(() => {
    if (advTotalRef.current !== totalAdvertisingExpenses) {
      setAdvExpenseUpdate(true);
      setTimeout(() => {
        setAdvExpenseUpdate(false);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [totalAdvertisingExpenses]);

  const createExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await createEventExpense(id, data);
    },
    onSuccess: async (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries(["event-detail", data.event.id]);
        queryClient.invalidateQueries(["variables-offer-pdf", data.event.id]);
        queryClient.invalidateQueries(["expenses-offer-pdf", data.event.id]);
      }, 500);
    },
  });

  const CUSTOM_STYLES = {
    container: (provided) => ({
      ...provided,
      border: "1px dashed #B3B3B3",
      margin: 0,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
      border: "none",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      border: "none",
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: 14,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black",
      border: "none",
      fontSize: 14,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "none",
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
  };

  const handleSaveNewExpense = async () => {
    setIsAdding(false);
    setSelectedGroup("");
    setInputHeader("");
    setInputValue("");
    setNotes("");
    await createExpenseMutation.mutate({
      amount: amountStrToInt(inputValue),
      subgroup: inputHeader && parseInt(inputHeader?.split(";id:")[1]),
      payment_method: "",
      check_number: "-1",
      description: notes ?? "",
      notes: notes ?? "",
      is_offer: true,
      exclude_artist: false,
      exclude_copro: false,
      exclude_final: false,
    });
    await queryClient.invalidateQueries(["event-detail", id]);
    await queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    await queryClient.invalidateQueries(["variables-offer-pdf", id]);
  };

  const handleCancelAdding = () => {
    setIsAdding(false);
    setNotes("");
  };

  const handleIsAdding = () => {
    setIsAdding(true);
  };

  const handleSelection = (value) => {
    setSelectedGroup(value);
  };

  const updateExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventExpense(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    },
  });

  const data = [];

  if (displayExpenses && advertisingExpenses) {
    // Map display expenses
    data.push(
      ...displayExpenses.map((expense) => ({
        header: expense.subgroup_name
          ? expense.subgroup_name.toUpperCase()
          : "",
        value: amountDisplay(expense.amount),
        otherParams: expense,
        format: "money",
        editable: true,
        isChanged: updatedProperties.includes(
          expense.subgroup_name.toUpperCase()
        ),
        msg: (
          <div className="border-2 border-dashed selection:bg-blue-900 selection:text-white">
            <EditableField
              key={expense.id}
              item={{
                value: expense.notes,
              }}
              className="bg-transparent"
              onBlur={async (value) => {
                await updateExpenseMutation.mutateAsync({
                  amount: expense.amount,
                  subgroup: inputHeader
                    ? parseInt(inputHeader.split(";id:")[1])
                    : null,
                  payment_method: "",
                  check_number: "-1",
                  description: value,
                  notes: value,
                  is_offer: true,
                  exclude_artist: false,
                  exclude_copro: false,
                  exclude_final: false,
                  id: expense.id,
                  expense_id: expense.id,
                });
              }}
            />
          </div>
        ),
      }))
    );

    // Add advertising total
    data.push({
      header: "ADVERTISING TOTAL",
      value: amountDisplay(totalAdvertisingExpenses),
      highlight: true,
      isChanged: advExpenseUpdate,
    });

    // Map advertising expenses
    data.push(
      ...advertisingExpenses.map((expense) => ({
        header: expense.subgroup_name
          ? expense.subgroup_name.toUpperCase()
          : "",
        value: amountDisplay(expense.amount),
        otherParams: expense,
        format: "money",
        editable: true,
        isChanged: updatedProperties.includes(
          expense.subgroup_name.toUpperCase()
        ),
      }))
    );
  }

  if (isAdding && selectedGroup) {
    data.push({
      headerFormat: "select",
      headerSelectOptions: groups.data
        ?.flatMap(
          (group) =>
            group.name === selectedGroup.label &&
            group?.subgroups?.map((subgroup) => {
              return {
                label: subgroup.name,
                value:
                  subgroup.name.toLowerCase().replace(/\s+/g, "-") +
                  ";id:" +
                  subgroup.id,
              };
            })
        )
        .filter((item) => !!item),
      value: "",
      isNew: true,
      format: "money",
      accessor: "is_new",
    });
  }

  const handleChangeInputValue = (accessor, value, otherParams, callback) => {
    setInputValue(value);
    if (accessor != "is_new") {
      setInputValue(value);
      updateExpenseMutation.mutate({
        amount: amountStrToInt(value),
        subgroup: otherParams.subgroup,
        payment_method: "",
        check_number: "-1",
        description: notes ?? "",
        notes: notes ?? "",
        is_offer: true,
        exclude_artist: false,
        exclude_copro: false,
        exclude_final: false,
        expense_id: otherParams.id,
      });
      //   handleUpdateExpense &&
      //     handleUpdateExpense(value, otherParams, accessor, callback);
    }
  };

  const handleChangeInputHeader = (accessor, value, otherParams) => {
    setInputHeader(value);
  };
  return (
    <div className="w-full">
      <EditableTable
        rows={data}
        onInputChange={handleChangeInputValue}
        onHeaderChange={handleChangeInputHeader}
        event={event}
      />
      <div
        //      className={classNames(
        //54-            enabled ? 'translate-x-5' : 'translate-x-0',
        //55-            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        //56-          )}

        className={classNames(
          !isAdding
            ? "border-0"
            : "bg-cave-blue-1 border-cave-blue-3 border-4 p-4 m-2",
          "w-full inline-flex justify-end gap-2"
        )}
      >
        {!isAdding ? (
          <button
            onClick={handleIsAdding}
            className="bg-gray-400 disabled:bg-gray-200 rounded px-4 py-1 text-white font-sans text-lg mt-2"
          >
            Add Expense
          </button>
        ) : (
          <div className="flex w-full gap-20">
            <div className="mt-2 w-full">
              <Select
                options={groups.data?.map((group) => {
                  return {
                    label: group.name,
                    value:
                      group.name.toLowerCase().replace(/\s+/g, "-") +
                      ";id:" +
                      group.id,
                  };
                })}
                value={selectedGroup}
                styles={CUSTOM_STYLES}
                onChange={handleSelection}
                placeholder="Choose Subgroup"
              />
              <label className="text-sm" htmlFor="notes">
                Notes
              </label>
              <input
                type="text"
                className="border-2 border-dotted  w-full p-1 mt-2 text-sm"
                placeholder="Notes (optional)"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="flex self-start gap-2">
              <button
                onClick={handleCancelAdding}
                className="bg-cave-red disabled:bg-gray-200 rounded px-4 py-1 text-white font-sans text-sm mt-2"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSaveNewExpense(id)}
                className="bg-blue-500 disabled:bg-gray-200 rounded px-4 py-1 text-white font-sans text-sm mt-2"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function logChanges(previousData, newData, updateState) {
  function findChanges(oldObj, newObj, path = "") {
    const changes = [];
    Object.keys(newObj).forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      if (
        oldObj[key] &&
        typeof newObj[key] === "object" &&
        newObj[key] !== null &&
        !Array.isArray(newObj[key])
      ) {
        const nestedChanges = findChanges(oldObj[key], newObj[key], newPath);
        if (Object.keys(nestedChanges).length > 0) {
          Object.assign(changes, nestedChanges);
        }
      } else if (newObj[key] !== oldObj[key]) {
        changes.push(newPath);
      }
    });
    return changes;
  }

  const changes = findChanges(previousData, newData);
  if (changes.length > 0) {
    updateState &&
      updateState((existingState) => {
        return [...existingState, ...changes];
      });
  }
}

function useQueryWithChangeDetection(queryKey, queryFn, options, updateState) {
  const queryInfo = useQuery(queryKey, queryFn, options);
  const previousDataRef = useRef();

  useEffect(() => {
    if (
      queryInfo.data &&
      previousDataRef.current &&
      queryInfo.data !== previousDataRef.current
    ) {
      logChanges(previousDataRef.current, queryInfo.data, updateState);
    }
    previousDataRef.current = queryInfo.data;
  }, [queryInfo.data]);

  return queryInfo;
}

export const EventEditOffer = () => {
  // state variables
  const [displayExpenses, setDisplayExpenses] = useState([]);
  const [advertisingExpenses, setAdvertisingExpenses] = useState([]);
  const [totalAdvertisingExpenses, setTotalAdvertisingExpenses] = useState(0);
  const [grossPotential, setGrossPotential] = useState(0);
  const [ticketFees, setTicketFees] = useState(0);
  const [getEventDate, setGetEventDate] = useState("");
  const [dealHeader, setDealHeader] = useState("");
  const [venueID, setVenueID] = useState(null);
  const [isRefetchingPDF, setIsRefetching] = useState(false);
  const [isPDFGenerated, setIsPDFGenerated] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [updatedProperties, setUpdatedProperties] = useState([]);
  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };

  const { user } = useUser();

  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // GET requests
  const event = useQueryWithChangeDetection(
    ["event-detail", id],
    () => getEvent(id),
    {},
    setUpdatedProperties
  );
  const expenses = useQueryWithChangeDetection(
    ["expenses-offer-pdf", id],
    () => getEventPDFExpenses(id),
    {},
    setUpdatedProperties
  );
  const variables = useQueryWithChangeDetection(
    ["variables-offer-pdf", id],
    () => getEventOfferVariables(id),
    {},
    setUpdatedProperties
  );
  const rollups = useQueryWithChangeDetection(
    ["event-rollups", id],
    () => getEventRollups(id),
    {},
    setUpdatedProperties
  );
  const venue = useQueryWithChangeDetection(
    ["venue-detail", venueID],
    () => getVenue(venueID),
    {
      enabled: !!venueID,
    },
    setUpdatedProperties
  );
  const pdfs = useQuery(["event-pdfs", id], () => getEventPDFs(id), {
    refetchInterval: () => (isRefetchingPDF ? 500 : false),
  });
  const venues = useQuery(["venues"], () => getVenues());
  const artists = useQuery(["artists"], () => getArtists());
  // PUT/POST requests

  const updateEventOfferMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventOffer(id, data);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          offer_type: newData.offer_type,
          offer_headliner: newData.offer_headliner,
          offer_support_1: newData.offer_support_1,
          offer_support_2: newData.offer_support_2,
          offer_opener: newData.offer_opener,
        });
      }
      return { previousEventData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
      toast.error("Error updating event");
    },
  });
  const createExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await createEventExpense(id, data);
    },
    onSuccess: (id, data) => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    },
    onError: (error, variables, context) => {
      toast.error("Error creating expense");
    },
  });
  const updateExpenseMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventExpense(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    },
    onError: (err, newData, context) => {
      toast.error("Error updating expense");
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
    },
  });
  const createPDFMutation = useMutation({
    mutationFn: (data) => {
      console.dir(data);
      return createEventPDF(id, data);
    },
    onError: (error, variables, context) => {
      toast.error("Error generating PDF");
      console.dir(error);
      console.dir(variables);
      console.dir(context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["event-pdfs", id]);
      // Restart our countdown
      setIsRefetching(true);
      setTimeout(() => {
        setIsRefetching(false);
        setIsPDFGenerated(true);
      }, 10000);
    },
  });
  const findArtistsFromIDs = (ids) => {
    return artists.data.filter((artist) => ids.includes(artist.id));
  };
  const updateEventMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEvent(id, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
      if (data.venue.id !== venue.data.id) {
        queryClient.invalidateQueries(["venue-detail", data.venue.id]);
        queryClient.refetchQueries(["venue-detail", data.venue.id]);
      }
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData && newData.age_requirement !== undefined) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          age_requirement:
            newData.age_requirement !== undefined
              ? newData.age_requirement
              : previousEventData.age_requirement,
          facility_fee:
            newData.facility_fee !== undefined
              ? newData.facility_fee
              : previousEventData.facility_fee,
          who_sells_merch:
            newData.who_sells_merch !== undefined
              ? newData.who_sells_merch
              : previousEventData.who_sells_merch,
          venue: {
            ...previousEventData.venue,
            name:
              newData.venue_id !== undefined
                ? venues.data.find((venue) => venue.id === newData.venue_id)
                    ?.name
                : previousEventData.venue.name,
          },
          artists:
            newData.artists !== undefined
              ? findArtistsFromIDs(newData.artists)
              : previousEventData.artists,
        });
      }
      variables.refetch();
      return { previousEventData };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
      toast.error("Error updating event");
    },
  });
  const updateEventManifest = useMutation({
    mutationFn: async (data) => {
      const { kills, is_offer, manifest_id, sort_order } = data;

      // Prepare the data to be sent. Only include sort_order if it's provided.
      const updateData = {
        ...data,
        sort_order: sort_order,
        kills: 0,
        is_offer: true,
      };
      return await updateManifest(updateData.manifest_id, updateData);
    },
    onError: (err, newManifestData, context) => {
      const id = newManifestData?.eventId;
      toast.error("Error updating manifest");
      queryClient.setQueryData(["event-detail", id], context.previousEventData);
    },
    onSuccess: async (newManifestData) => {
      const id = newManifestData?.eventId;
      await event?.refetch();
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
      queryClient.invalidateQueries(["event-rollups", id]);
    },
  });
  const updateEventAccountingMutation = useMutation({
    mutationFn: async (data) => {
      return await updateEventAccounting(id, data);
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(["event-detail", id]);
      const previousEventData = queryClient.getQueryData(["event-detail", id]);
      if (previousEventData) {
        queryClient.setQueryData(["event-detail", id], {
          ...previousEventData,
          who_sells_merch:
            newData.who_sells_merch !== undefined
              ? newData.who_sells_merch
              : previousEventData.who_sells_merch,
          artist_hard_merch_percentage:
            newData.artist_hard_merch_percentage !== undefined
              ? newData.artist_hard_merch_percentage
              : previousEventData.artist_hard_merch_percentage,
          artist_soft_merch_percentage:
            newData.artist_soft_merch_percentage !== undefined
              ? newData.artist_soft_merch_percentage
              : previousEventData.artist_soft_merch_percentage,
        });
      }
      return { previousEventData };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["event-detail", id]);
      queryClient.invalidateQueries(["variables-offer-pdf", id]);
      queryClient.invalidateQueries(["expenses-offer-pdf", id]);
    },
    onError: (error) => {
      toast.error("Error updating ticket fees");
      console.error("Error updating offer ticket fees:", error);
    },
  });
  const updateVenueMutation = useMutation({
    mutationFn: async (data) => {
      return await updateVenue(venueID, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["venue-detail", id]);
    },
  });

  useEffect(() => {
    if (updatedProperties?.length > 0) {
      setTimeout(() => {
        setUpdatedProperties([]);
      }, FIELD_UPDATE_ANIMATION_TIME);
    }
  }, [updatedProperties]);

  useEffect(() => {
    if (expenses && expenses.data) {
      setDisplayExpenses(
        expenses.data.filter((expense) => expense.group_name !== "Advertising")
      );
      let advExpenses = expenses.data.filter(
        (expense) => expense.group_name === "Advertising"
      );
      setAdvertisingExpenses(advExpenses);
      setTotalAdvertisingExpenses(
        advExpenses.reduce((total, expense) => total + expense.amount, 0)
      );
    }
  }, [expenses?.data]);

  useEffect(() => {
    if (event && event.data) {
      setGrossPotential(
        event.data.manifests.reduce((total, manifest) => {
          const ticketFees = amountStrToInt(manifest.ticket_fees) || 0;
          return total + (manifest.price + ticketFees) * manifest.qty;
        }, 0)
      );
      setTicketFees(
        event.data.manifests.reduce((total, manifest) => {
          const ticketFees = amountStrToInt(manifest.ticket_fees) || 0;
          return total + manifest.qty * ticketFees;
        }, 0)
      );
      setGetEventDate(`[${event.data.date}]`);
    }
    if (!venueID && event?.data?.venue) {
      setVenueID(event.data.venue.id);
    }
  }, [event?.data]);

  useEffect(() => {
    if (!event.data) return;
    let dealHeader = `VERSUS ${event.data.offer_percentage}% DEAL`;
    if (event.data.offer_type === "flat") {
      dealHeader = "FLAT";
    } else if (event.data.offer_type === "promoter-profit") {
      dealHeader = "SPLIT POINT";
    } else if (event.data.offer_type === "nbor") {
      dealHeader = `DOOR DEAL`;
    }
    setDealHeader(dealHeader);
  }, [event?.data]);

  // loading / error checks
  if (
    rollups.isLoading ||
    expenses.isLoading ||
    event.isLoading ||
    variables.isLoading ||
    venue.isLoading ||
    artists.isLoading ||
    venues.isLoading
  )
    return (
      <div className="text-center text-gray-700 dark:text-gray-300">
        Loading...
      </div>
    );
  if (
    rollups.isError ||
    expenses.isError ||
    event.error ||
    variables.error ||
    venue.error ||
    artists.error ||
    venues.isLoading
  )
    return <div>Error loading data</div>;

  // constants
  const AGE_OPTIONS = [
    { label: "All Ages", value: "all-ages" },
    { label: "16+", value: "16-up" },
    { label: "17+", value: "17-up" },
    { label: "18+", value: "18-up" },
    { label: "19+", value: "19-up" },
    { label: "21+", value: "21-up" },
  ];
  const EVENT_OFFER_TYPE_OPTIONS = [
    { label: "Flat", value: "flat" },
    { label: "Promoter Profit", value: "promoter-profit" },
    { label: "Versus", value: "vs" },
    { label: "Door Deal", value: "nbor" },
  ];
  const WHO_SELLS_MERCH_OPTIONS = [
    { label: "Mammoth", value: "mammoth" },
    { label: "Artist", value: "artist" },
    { label: "Venue", value: "venue" },
  ];

  const handleMapOptions = (options) => {
    return options.map((option, index) => {
      return { value: index, label: option?.name, id: option?.id };
    });
  };

  const VENUE_OPTIONS = venues.data.map((option, index) => {
    return { value: option.name, label: option.name };
  });
  const ARTIST_OPTIONS = handleMapOptions(artists.data);

  const EVENT_REQUIRED_PARAMS = {
    name: event.data.name,
    date: event.data.date,
    artists: event.data.artists ? [event.data.artists[0].id] : [0],
    state: event.data.state,
    age_requirement: event.data.age_requirement,
    abbr: event.data.abbr,
    description: event.data.description,
    door_time: event.data.door_time,
    opener_time: event.data.opener_time,
    support_time: event.data.support_time,
    headliner_time: event.data.headliner_time,
    curfew_time: event.data.curfew_time,
    offer_notes: event.data.offer_notes,
    confirmation_notes: event.data.confirmation_notes,
    venue_id: event.data.venue.id ?? 0,
    has_insurance: event.data.has_insurance,
    has_credit_card_fees: event.data.has_credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_has_rent: event.data.offer_has_rent,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    offer_has_gmr: event.data.offer_has_gmr ?? false,
    offer_gmr: event.data.offer_gmr,
    facility_fee: event.data.facility_fee,
    offer_support_1: event.data.offer_support_1,
    offer_support_2: event.data.offer_support_2,
    offer_opener: event.data.offer_opener,
    offer_billing: event.data.offer_billing,
    charity: event.data.charity,
    platinum_lift: event.data.platinum_lift,
  };
  const EVENT_OFFER_REQUIRED_PARAMS = {
    offer_type: event.data.offer_type,
    offer_agent: event.data.offer_agent,
    offer_headliner: event.data.offer_headliner,
    offer_support_1: event.data.offer_support_1,
    offer_support_2: event.data.offer_support_2,
    offer_opener: event.data.offer_opener,
    offer_billing: event.data.offer_billing,
    offer_stipulations: event.data.offer_stipulations,
    offer_avails_1: event.data.offer_avails_1,
    offer_avails_2: event.data.offer_avails_2,
    facility_fee: event.data.facility_fee,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_sesac: event.data.has_sesac,
    offer_comp_tax: event.data.offer_comp_tax,
    offer_gmr: event.data.offer_gmr,
    offer_has_gmr: event.data.offer_has_gmr,
    offer_minimum_rent: event.data.offer_minimum_rent,
    offer_rent_per_paid: event.data.offer_rent_per_paid,
    offer_onsale_info: event.data.offer_onsale_info,
    offer_presale_info: event.data.offer_presale_info,
    offer_notes: event.data.offer_notes,
    offer_percentage: event.data.offer_percentage,
  };

  const EVENT_ACCOUNTING_REQUIRED_PARAMS = {
    concessions: event.data.concessions,
    merch: event.data.merch,
    rebate: event.data.rebate,
    charity: event.data.charity,
    facility_fee: event.data.facility_fee,
    drop_count: event.data.drop_count,
    platinum_lift: event.data.platinum_lift,
    vip_lift: event.data.vip_lift,
    has_ascap: event.data.has_ascap,
    has_bmi: event.data.has_bmi,
    has_insurance: event.data.has_insurance,
    has_sesac: event.data.has_sesac,
    has_credit_card_fees: event.data.has_credit_card_fees,
    credit_card_fees: event.data.credit_card_fees,
    has_ticket_commission: event.data.has_ticket_commission,
    ticket_commission: event.data.ticket_commission,
    insurance_amount: event.data.insurance_amount,
    offer_comp_tax: event.data.offer_comp_tax,
    offer_has_comp_tax: event.data.offer_has_comp_tax,
    offer_gmr: event.data.offer_gmr,
    offer_has_rent: event.data.offer_has_rent,
    hold_tm: event.data.hold_tm,
    hold_etix: event.data.hold_etix,
    hold_venue: event.data.hold_venue,
    hold_fan_club: event.data.hold_fan_club,
    hold_outlets: event.data.hold_outlets,
    hold_vip: event.data.hold_vip,
    mammoth_percentage: event.data.mammoth_percentage,
    building_percentage: event.data.building_percentage,
    who_sells_merch: event.data.who_sells_merch,
    artist_soft_merch_percentage: event.data.artist_soft_merch_percentage,
    artist_hard_merch_percentage: event.data.artist_hard_merch_percentage,
  };

  const handleUpdateExpense = (value, otherParams, accessor, callback) => {
    if (accessor === "is_new") {
      createExpenseMutation.mutate(
        {
          payment_method: "",
          check_number: "",
          description: "",
          amount: amountStrToInt(value),
          notes: accessor === "notes" ? value : otherParams.notes || "",
          is_offer: true,
          exclude_artist: false,
          exclude_copro: false,
          exclude_final: false,
        },
        {
          onSuccess: () => {
            callback?.();
          },
        }
      );
    } else if (otherParams && otherParams.id) {
      updateExpenseMutation.mutate(
        {
          ...otherParams,
          expense_id: otherParams.id,
          payment_method: otherParams.payment_method || "",
          check_number: otherParams.check_number || "",
          description: otherParams.description || "",
          notes: accessor === "notes" ? value : otherParams.notes || "",
          exclude_artist: false,
          exclude_copro: false,
          exclude_final: false,
          amount:
            accessor === "accessor"
              ? amountStrToInt(value)
              : amountStrToInt(otherParams.amount),
        },
        {
          onSuccess: () => {
            callback?.();
          },
        }
      );
    } else {
      console.warn("Invalid parameters for updating expense");
    }
  };

  const handleUpdateEvent = (accessor, value, otherParams, callback) => {
    updateEventMutation.mutate(
      Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value }),
      {
        onSuccess: () => {
          callback?.();
        },
      }
    );
  };

  const handleUpdateTime = (accessor, value, otherParams, callback) => {
    if (value === undefined || value === "") {
      return; // Exit if value is undefined or empty
    }
    const formattedValue = formatToHHMMSS(value);
    if (formattedValue && !formattedValue?.toLowerCase()?.includes("invalid")) {
      handleUpdateEvent(accessor, formattedValue, otherParams, callback);
    } else {
      callback?.();
    }
  };

  const handleChangeInput = (
    accessor,
    value,
    otherParams,
    eventId,
    callback
  ) => {
    if (
      accessor == "name" ||
      accessor == "qty" ||
      accessor == "price" ||
      accessor == "comps" ||
      accessor == "ticket_fees"
    ) {
      console.log("otherParams", otherParams);
      const body = Object.assign(
        {
          manifest_id: otherParams.id,
          name: otherParams.name,
          qty: otherParams.qty,
          price: otherParams.price,
          on_sale: otherParams.on_sale,
          comps: otherParams.comps,
          eventId: eventId,
          ticket_fees: amountStrToInt(otherParams.ticket_fees),
          sort_order: otherParams.sort_order,
        },
        { [accessor]: value }
      );
      body.on_sale = new Intl.DateTimeFormat("en-CA").format(
        new Date(body.on_sale)
      );
      if (typeof body.price === "string")
        body.price = amountStrToInt(body.price);
      body.qty = parseInt(body.qty);
      updateEventManifest.mutate(body, {
        onSuccess: () => {
          callback?.();
        },
      });
    } else if (accessor === "facility_fee") {
      if (typeof value === "string") value = amountStrToInt(value);
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value })
      );
    }
  };

  const handleUpdateAgesTable = (accessor, value, otherParams, callback) => {
    if (
      accessor === "artist_soft_merch_percentage" ||
      accessor === "artist_hard_merch_percentage"
    ) {
      updateEventAccountingMutation.mutate(
        Object.assign(EVENT_ACCOUNTING_REQUIRED_PARAMS, {
          [accessor]: parseInt(value),
        }),
        {
          onSuccess: () => {
            callback?.();
          },
        }
      );
    } else if (accessor === "who_sells_merch") {
      updateEventAccountingMutation.mutate(
        Object.assign(EVENT_ACCOUNTING_REQUIRED_PARAMS, { [accessor]: value })
      );
    } else {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { [accessor]: value })
      );
    }
  };

  const handleUpdateEventOffer = (accessor, value, otherParams, callback) => {
    if (accessor === "artists") {
      if (value?.length > 0) {
        updateEventMutation.mutate(
          Object.assign(EVENT_REQUIRED_PARAMS, {
            [accessor]: value.map((artist) => artist.id),
          }),
          {
            onSuccess: () => {
              callback?.();
            },
          }
        );
      } else if (value.length === 0) {
        setTimeout(() => {
          queryClient.invalidateQueries(["event-detail", event.data.id]);
        }, 500);
      }
    } else {
      updateEventOfferMutation.mutate(
        Object.assign(EVENT_OFFER_REQUIRED_PARAMS, { [accessor]: value }),
        {
          onSuccess: () => {
            callback?.();
          },
        }
      );
    }
  };

  const handleUpdateVariablesTable = (
    accessor,
    value,
    otherParams,
    callback
  ) => {
    if (accessor === "offer_comp_tax") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_comp_tax: amountStrToInt(value),
        }),
        {
          onSuccess: () => {
            callback?.();
          },
        }
      );
    } else if (accessor === "offer_gmr") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_gmr: amountStrToInt(value),
        }),
        {
          onSuccess: () => {
            callback?.();
          },
        }
      );
    } else if (
      accessor === "credit_card_fees" ||
      accessor === "ticket_commission"
    ) {
      updateEventAccountingMutation.mutate(
        Object.assign(EVENT_ACCOUNTING_REQUIRED_PARAMS, {
          [accessor]: amountStrToInt(value),
        })
      );
    }
  };

  const handleVariablesToggle = (toggleData) => {
    if (toggleData.accesor === "offer_comp_tax") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_comp_tax: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "offer_gmr") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_gmr: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "rent") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          offer_has_rent: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "ascap") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_ascap: toggleData.state })
      );
    } else if (toggleData.accesor === "bmi") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_bmi: toggleData.state })
      );
    } else if (toggleData.accesor === "sesac") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, { has_sesac: toggleData.state })
      );
    } else if (toggleData.accesor === "insurance_amount") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_insurance: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "credit_card_fees") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_credit_card_fees: toggleData.state,
        })
      );
    } else if (toggleData.accesor === "ticket_commission") {
      updateEventMutation.mutate(
        Object.assign(EVENT_REQUIRED_PARAMS, {
          has_ticket_commission: toggleData.state,
        })
      );
    }
  };

  const handleUpdateVenueDetails = (accessor, value) => {
    if (accessor == "venue_name") {
      updateEventMutation.mutate({
        ...EVENT_REQUIRED_PARAMS,
        venue_id:
          venues.data.find((venue) => venue.name === value)?.id ||
          event.data.venue.id,
      });
    }
  };

  const tempFormatFix = (value) => {
    if (typeof value === "string" && value === "0.00") return 0;
    else if (typeof value === "string") return parseInt(value);
    else return value;
  };

  const getDealText = () => {
    if (event.data.offer_type === "vs") {
      return `VERSUS ${event.data.offer_percentage}% of net receipts after approved expenses`;
    } else if (event.data.offer_type === "promoter-profit") {
      return `PLUS ${event.data.offer_percentage}% After split point.`;
    } else if (event.data.offer_type === "flat") {
      return `FLAT`;
    }
  };

  const handleCreatePDF = () => {
    const data = {
      name: "Offer",
      file_type: "offer",
      note: "",
    };
    createPDFMutation.mutate(data);
  };

  const handleViewPDF = () => {
    const lastGeneratedPDF = pdfs.data?.[0];
    if (lastGeneratedPDF) {
      window.open(lastGeneratedPDF.file_url, "_blank", "noopener,noreferrer");
    }
  };

  const getPDFButtonText = () => {
    if (isRefetchingPDF) {
      return (
        <>
          Generating... &nbsp;&nbsp;
          <div className="spinner"></div>
        </>
      );
    } else if (pdfs.data?.length > 0) return "Regenerate PDF";
    else return "Create Offer PDF";
  };

  const calculateTotal = () => {
    let total = variables.data.variables_total;
    // if (event.data.offer_has_rent)
    //   total += tempFormatFix(event.data.tickets_total * 100);
    return total;
  };

  return (
    <div>
      {showHeader && (
        <div className="fixed top-0 left-0 right-0 z-[1000]">
          <Header />
        </div>
      )}
      <div
        className={`flex flex-col px-6 py-12 mx-auto md:h-screen min-h-screen ${showHeader ? "pt-16" : ""}`}
      >
        <div className={`${showHeader ? "pt-48" : ""}`}>
          <BreadCrumbs
            links={[
              { text: "Events", url: `/events/` },
              {
                text: `${event.data.name} ${getEventDate}`,
                url: `/events/${event.data.id}`,
              },
              { text: "Edit: Offer" },
            ]}
          />
          <WalkoutPot
            walkout_potential={variables.data.walkout_potential}
            showHeader={showHeader}
            toggleHeader={toggleHeader}
          />
        </div>
        <h3 className="text-xl font-bold mb-4 dark:text-white">
          Edit Offer - {event.data.name}
        </h3>
        <div className="py-6 min-w-[1500px]">
          <div className="flex flex-row gap-4">
            <div className="w-1/3 flex flex-col gap-4">
              <div className="flex flex-col items-center justify-start gap-1">
                <img
                  src={"/static/images/mammoth-logo.png"}
                  width={250}
                  height={200}
                />
                <p className="font-montserrat text-xs dark:text-white">
                  1520 HASKELL AVE LAWRENCE, KS 66044
                </p>
                <p className="font-montserrat text-xs dark:text-white mb-4">
                  785.749.7475 PH | 785.865.4110 FX
                </p>
                <div className="flex flex-col gap-2 items-center">
                  <Button onClick={handleCreatePDF}>
                    {getPDFButtonText()}
                  </Button>
                  {pdfs.data?.length > 0 && (
                    <Button
                      disabled={isRefetchingPDF}
                      variant="secondary"
                      onClick={handleViewPDF}
                    >
                      View PDF
                    </Button>
                  )}
                  <div>
                    {event.isSuccess && (
                      <StateSwitcher eventId={event.data.id} />
                    )}
                  </div>
                  <div>
                    {event.isSuccess && <DateChanger eventId={event.data.id} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/3 flex flex-col">
              <div className="flex flex-row">
                <div className="w-1/2">
                  <DataGrid
                    className="border border-gray-300 border-dashed"
                    onBlur={(val, field) => {
                      handleUpdateEventOffer(field, val);
                    }}
                    cols={[
                      {
                        header: "AGENT",
                        value: event.data.offer_agent,
                        field: "offer_agent",
                        isChanged: updatedProperties.includes("offer_agent"),
                      },
                      {
                        header: "BUYER",
                        value: variables.data.buyer_name,
                        field: "buyer_name",
                        editable: false,
                        isChanged: updatedProperties.includes("buyer_name"),
                      },
                      {
                        header: "HEADLINER",
                        value: handleMapOptions(event.data.artists),
                        isMulti: true,
                        type: "select",
                        options: ARTIST_OPTIONS,
                        field: "artists",
                        onValueChange: (val) => {
                          handleUpdateEventOffer("artists", val);
                        },
                      },
                      {
                        header: "SUPPORT 1",
                        value: event.data.offer_support_1,
                        field: "offer_support_1",
                        isChanged:
                          updatedProperties.includes("offer_support_1"),
                      },
                      {
                        header: "SUPPORT 2",
                        value: event.data.offer_support_2,
                        field: "offer_support_2",
                        isChanged:
                          updatedProperties.includes("offer_support_2"),
                      },
                      {
                        header: "OPENER",
                        value: event.data.offer_opener,
                        field: "offer_opener",
                        isChanged: updatedProperties.includes("offer_opener"),
                      },
                      {
                        header: "BILLING",
                        value: event.data.offer_billing,
                        field: "offer_billing",
                        isChanged: updatedProperties.includes("offer_billing"),
                      },
                      {
                        header: "STIPULATIONS",
                        value: event.data.offer_stipulations,
                        field: "offer_stipulations",
                        isChanged:
                          updatedProperties.includes("offer_stipulations"),
                      },
                      {
                        header: "AVAILS",
                        value: event.data.offer_avails_1,
                        field: "offer_avails_1",
                        isChanged: updatedProperties.includes("offer_avails_1"),
                      },
                      {
                        header: "AVAILS",
                        value: event.data.offer_avails_2,
                        field: "offer_avails_2",
                        isChanged: updatedProperties.includes("offer_avails_2"),
                      },
                    ]}
                  />
                </div>
                <div className="w-1/2 flex flex-col">
                  <EditableTable
                    header={{ title: "VENUE INFORMATION" }}
                    rows={[
                      {
                        header: "NAME",
                        value: selectValueToOption(
                          event.data.venue.name,
                          VENUE_OPTIONS
                        ),
                        format: "select",
                        options: VENUE_OPTIONS,
                        accessor: "venue_name",
                        customStyles: {
                          container: {
                            zIndex: 25,
                          },
                        },
                      },
                      {
                        header: "ADDRESS",
                        value: event.data.venue.address,
                        editable: false,
                        isChanged: updatedProperties.includes("venue.address"),
                      },
                      {
                        header: "CITY",
                        value: event.data.venue.city,
                        editable: false,
                        isChanged: updatedProperties.includes("venue.city"),
                      },
                      {
                        header: "STATE",
                        value: event.data.venue.state,
                        editable: false,
                        isChanged: updatedProperties.includes("venue.state"),
                      },
                      {
                        header: "ZIPCODE",
                        value: event.data.venue.zipcode,
                        editable: false,
                        isChanged: updatedProperties.includes("venue.zipcode"),
                      },
                      {
                        header: "PHONE",
                        value: event.data.venue.phone,
                        editable: false,
                        isChanged: updatedProperties.includes("venue.phone"),
                      },
                      {
                        header: "URL",
                        value: event.data.venue.url,
                        editable: false,
                        isChanged: updatedProperties.includes("venue.url"),
                      },
                    ]}
                    onInputChange={handleUpdateVenueDetails}
                  />
                  {!venue.data.capacity && (
                    <span className="bg-red-500 text-white px-2 py-1 rounded">
                      {" "}
                      no CAPACITY defined for venue{" "}
                      <a
                        target="_blank"
                        href={`/venues/edit/${venue.data.id}`}
                        className="underline"
                        rel="noreferrer"
                      >
                        edit here
                      </a>
                    </span>
                  )}
                  {!venue.data.sales_tax && (
                    <span className="bg-red-500 text-white px-2 py-1 rounded">
                      {" "}
                      no SALES TAX defined for venue{" "}
                      <a
                        target="_blank"
                        href={`/venues/edit/${venue.data.id}`}
                        className="underline"
                        rel="noreferrer"
                      >
                        edit here
                      </a>
                    </span>
                  )}
                  <EditableTable
                    rows={[
                      {
                        header: "AGE REQUIREMENT",
                        value: selectValueToOption(
                          event.data.age_requirement,
                          AGE_OPTIONS
                        ),
                        accessor: "age_requirement",
                        format: "select",
                        options: AGE_OPTIONS,
                        customStyles: {
                          container: {
                            zIndex: 22,
                          },
                        },
                      },
                      {
                        header: "SOFT MERCH",
                        value: event.data.artist_soft_merch_percentage,
                        accessor: "artist_soft_merch_percentage",
                        format: "number",
                        isCustom: true,
                        custom_format: `${event.data.artist_soft_merch_percentage} / 100`,
                        isChanged: updatedProperties.includes(
                          "artist_soft_merch_percentage"
                        ),
                      },
                      {
                        header: "HARD MERCH",
                        value: event.data.artist_hard_merch_percentage,
                        accessor: "artist_hard_merch_percentage",
                        format: "number",
                        isCustom: true,
                        custom_format: `${event.data.artist_hard_merch_percentage} / 100`,
                        isChanged: updatedProperties.includes(
                          "artist_hard_merch_percentage"
                        ),
                      },
                      {
                        header: "WHO SELLS",
                        value: selectValueToOption(
                          event.data.who_sells_merch,
                          WHO_SELLS_MERCH_OPTIONS
                        ),
                        accessor: "who_sells_merch",
                        format: "select",
                        options: WHO_SELLS_MERCH_OPTIONS,
                        customStyles: {
                          container: {
                            zIndex: 18,
                          },
                        },
                      },
                    ]}
                    onInputChange={handleUpdateAgesTable}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4 gap-4">
            <div className="w-1/3">
              <ExpenseTable
                id={id}
                event={event}
                handleUpdateExpense={handleUpdateExpense}
                displayExpenses={displayExpenses}
                advertisingExpenses={advertisingExpenses}
                totalAdvertisingExpenses={totalAdvertisingExpenses}
              />
            </div>
            <div className="w-2/3">
              <EventManifestTable
                event={event}
                eventId={event.data.id}
                handleChangeInput={handleChangeInput}
              />
              <div className="mt-4 flex flex-row gap-4">
                <div className="w-1/2">
                  <EditableTable
                    rows={[
                      {
                        header: "TOTAL CAPACITY",
                        value: rollups.data.capacity,
                        editable: false,
                        isChanged: updatedProperties.includes("capacity"),
                      },
                      {
                        header: "GROSS POTENTIAL",
                        value: amountDisplay(variables.data.gross_potential),
                        isChanged:
                          updatedProperties.includes("gross_potential"),
                        editable: false,
                      },
                      {
                        header: "TICKET FEES",
                        value: amountDisplay(variables.data.ticket_fees),
                        isChanged: updatedProperties.includes("ticket_fees"),
                        editable: false,
                      },
                      {
                        header: "ADJUSTED GROSS",
                        value: amountDisplay(variables.data.adjusted_gross),
                        isChanged: updatedProperties.includes("adjusted_gross"),
                        editable: false,
                      },
                      {
                        header: "TAX PERCENTAGE",
                        value: `${event.data.venue.sales_tax}%`,
                        isChanged: updatedProperties.includes("sales_tax"),
                        highlight: true,
                      },
                      {
                        header: "TOTAL TAX",
                        value: amountDisplay(variables.data.total_tax),
                        isChanged: updatedProperties.includes("total_tax"),
                        editable: false,
                      },
                      {
                        header: "NET POTENTIAL",
                        value: amountDisplay(variables.data.net_potential),
                        isChanged: updatedProperties.includes("net_potential"),
                        editable: false,
                      },
                    ]}
                  />
                </div>
                <div className="w-1/2">
                  <DealTable
                    dealAmount={amountDisplay(variables.data.the_deal_amount)}
                    dealDescription={getDealText()}
                    isChanged={updatedProperties.includes("the_deal_amount")}
                  />
                  <p className="mt-4 dark:text-white">
                    {event.data.offer_notes}
                  </p>
                </div>
              </div>
              <div className="mt-2 flex flex-row gap-4">
                <div className="w-1/2">
                  <EditableTable
                    rows={[
                      {
                        editable: false,
                        header: "TOTAL EXPENSES",
                        value: amountDisplay(variables.data.total_expenses),
                        isChanged:
                          updatedProperties.includes("total_expenses") ||
                          updatedProperties.includes("variables_total"),
                      },
                      ...(event.data.offer_type === "promoter-profit"
                        ? [
                            {
                              editable: false,
                              header: "PROMOTER PROFIT",
                              value: amountDisplay(
                                variables.data.promoter_profit
                              ),
                              isChanged:
                                updatedProperties.includes("promoter_profit"),
                            },
                            {
                              editable: false,
                              header: "SPLIT POINT",
                              value: amountDisplay(variables.data.split_point),
                              isChanged:
                                updatedProperties.includes("split_point"),
                            },
                          ]
                        : []),
                      ...(event.data.offer_type === "vs"
                        ? [
                            {
                              editable: false,
                              header: "LESS GUARANTEE",
                              value: amountDisplay(
                                variables.data.the_deal_amount
                              ),
                              isChanged:
                                updatedProperties.includes("the_deal_amount"),
                            },
                            {
                              editable: false,
                              header: "NET EXPENSES",
                              value: amountDisplay(
                                variables.data.total_expenses -
                                  variables.data.the_deal_amount
                              ),
                              isChanged:
                                updatedProperties.includes("net_expenses"),
                            },
                          ]
                        : []),
                    ]}
                    header={{ title: dealHeader }}
                    noBlue
                  />
                </div>
                <div className="w-1/2 flex flex-col">
                  <EditableTable
                    header={{ title: "WALK-OUT POTENTIAL" }}
                    rows={[
                      {
                        header: "EVENT OFFER TYPE",
                        value: selectValueToOption(
                          event.data.offer_type,
                          EVENT_OFFER_TYPE_OPTIONS
                        ),
                        accessor: "offer_type",
                        options: EVENT_OFFER_TYPE_OPTIONS,
                        editable: false,
                        format: "select",
                      },
                      {
                        header: "THE DEAL AMOUNT",
                        value: amountDisplay(variables.data.the_deal_amount),
                        editable: false,
                        isChanged:
                          updatedProperties.includes("the_deal_amount"),
                      },
                      {
                        header: "WALKOUT PERCENTAGE",
                        value: variables.data.walkout_percentage,
                        editable: false,
                        isChanged:
                          updatedProperties.includes("walkout_percentage"),
                      },
                      {
                        header: "OFFER PERCENTAGE",
                        value: event.data.offer_percentage,
                        accessor: "offer_percentage",
                        format: "number",
                        isChanged:
                          updatedProperties.includes("offer_percentage"),
                      },
                    ]}
                    noBlue
                    onInputChange={(accessor, value, otherParams, callback) => {
                      if (accessor === "offer_percentage") {
                        if (value === 0) value = 0;
                        if (value > 100) value = 100;
                        if (value === 100) value = 100;
                      }
                      return (
                        (accessor === "offer_type" ||
                          accessor === "offer_percentage") &&
                        handleUpdateEventOffer(
                          accessor,
                          value,
                          otherParams,
                          callback
                        )
                      );
                    }}
                  />
                  <TextAreaTable
                    header={"DEPOSITS"}
                    disabled
                    value={
                      "10%, 10 DAYS OUT. NO DEPOSITS UNDER $500 OR FOR SUPPORT."
                    }
                    noBlue
                  />
                </div>
              </div>
              <div className="mt-4">
                <ContactTable
                  eventID={id}
                  updatedProperties={updatedProperties}
                  eventData={event.data}
                />
              </div>
              <div className="mt-4 flex flex-row gap-4">
                <div className="w-1/2">
                  {event.data && (
                    <DataGrid
                      key={variables.data.id}
                      className="border-2 border-gray-300 border-dashed"
                      cols={[
                        {
                          header: "ASCAP",
                          field: "ascap",
                          value: amountDisplay(variables.data.ascap),
                          editable: false,
                          editableToggle: true,
                          editableToggleValue: event.data.has_ascap,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                        },
                        {
                          header: "BMI",
                          field: "bmi",
                          value: amountDisplay(variables.data.bmi),
                          editable: false,
                          editableToggle: true,
                          editableToggleValue: event.data.has_bmi,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                        },
                        {
                          header: "SESAC",
                          field: "sesac",
                          value: amountDisplay(variables.data.sesac),
                          editable: false,
                          editableToggle: true,
                          editableToggleValue: event.data.has_sesac,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                        },
                        {
                          header: "COMP TAX",
                          format: "money",
                          field: "offer_comp_tax",
                          value: amountDisplay(event.data.offer_comp_tax),
                          editable: !!event.data.offer_has_comp_tax,
                          editableToggle: true,
                          editableToggleValue: event.data.offer_has_comp_tax,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                        },
                        {
                          header: "GMR",
                          format: "money",
                          field: "offer_gmr",
                          value: amountDisplay(variables.data.gmr),
                          editable: false,
                          editableToggle: true,
                          editableToggleValue: event.data.offer_has_gmr,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                        },
                        {
                          header: "INSURANCE",
                          field: "insurance_amount",
                          value: amountDisplay(variables.data.insurance_amount),
                          editable: false,
                          editableToggle: true,
                          editableToggleValue: event.data.has_insurance,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                          msg: "55¢/head x capacity",
                        },
                        {
                          header: "RENT",
                          field: "rent",
                          value: !event.data.offer_has_rent
                            ? "FLAT"
                            : amountDisplay(event.data.tickets_total * 100),
                          editable: false,
                          editableToggle: true,
                          editableToggleValue: event.data.offer_has_rent,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                          toggle: event.data.offer_has_rent,
                          msg: "$1/per",
                        },
                        {
                          header: "CREDIT CARDS",
                          format: "money",
                          field: "credit_card_fees",
                          value: amountDisplay(variables.data.credit_card_fees),
                          editable: event.data.has_credit_card_fees !== false,
                          editableToggle: true,
                          editableToggleValue: event.data.has_credit_card_fees,
                          onToggleChange: (value, field) =>
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            }),
                          msg: "3.50%",
                        },
                        {
                          header: "TICKET COMMISSION",
                          format: "money",
                          field: "ticket_commission",
                          value: amountDisplay(
                            variables.data.ticket_commission
                          ),
                          editable: !!event.data.has_ticket_commission,
                          editableToggle: true,
                          editableToggleValue: event.data.ticket_commission,
                          onToggleChange: (value, field) => {
                            handleVariablesToggle({
                              field,
                              value,
                              accesor: field,
                              state: value,
                            });
                          },
                          msg: "3%/CAP",
                        },
                      ]}
                      header={
                        <div className="bg-gray-300 text-sm flex justify-between font-bold">
                          <div>VARIABLE.EXPENSES.TOTAL</div>

                          <div>{amountDisplay(calculateTotal())}</div>
                        </div>
                      }
                      blue={false}
                      onBlur={(value, field) => {
                        handleUpdateVariablesTable(field, value);
                      }}
                    />
                  )}
                  {/* <EditableTable
                    rows={[
                      {
                        header: "ASCAP",
                        accessor: "ascap",
                        value: amountDisplay(variables.data.ascap),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_ascap,
                        isChanged: updatedProperties.includes("ascap"),
                      },
                      {
                        header: "BMI",
                        accessor: "bmi",
                        value: amountDisplay(variables.data.bmi),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_bmi,
                        isChanged: updatedProperties.includes("bmi"),
                      },
                      {
                        header: "SESAC",
                        accessor: "sesac",
                        value: amountDisplay(variables.data.sesac),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_sesac,
                        isChanged: updatedProperties.includes("sesac"),
                      },
                      {
                        header: "COMP TAX",
                        format: "money",
                        accessor: "offer_comp_tax",
                        value: amountDisplay(event.data.offer_comp_tax),
                        editable: !!event.data.offer_has_comp_tax,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.offer_has_comp_tax,
                        isChanged: updatedProperties.includes("offer_comp_tax"),
                      },
                      {
                        header: "INSURANCE",
                        accessor: "insurance_amount",
                        value: amountDisplay(variables.data.insurance_amount),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_insurance,
                        msg: "55¢/head x capacity",
                        isChanged:
                          updatedProperties.includes("insurance_amount"),
                      },
                      {
                        header: "RENT",
                        accessor: "rent",
                        value: !event.data.offer_has_rent
                          ? "FLAT"
                          : amountDisplay(event.data.tickets_total * 100),
                        editable: false,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.offer_has_rent,
                        msg: "$1/per",
                        isChanged:
                          updatedProperties.includes("offer_has_rent") ||
                          updatedProperties.includes("rent_rate"),
                      },
                      {
                        header: "CREDIT CARDS",
                        format: "money",
                        accessor: "credit_card_fees",
                        value: amountDisplay(variables.data.credit_card_fees),
                        editable: !!event.data.has_credit_card_fees,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_credit_card_fees,
                        msg: "3.50%",
                        isChanged:
                          updatedProperties.includes("credit_card_fees"),
                      },
                      {
                        header: "TICKET COMMISSION",
                        format: "money",
                        accessor: "ticket_commission",
                        value: amountDisplay(variables.data.ticket_commission),
                        editable: !!event.data.has_ticket_commission,
                        onToggle: handleVariablesToggle,
                        toggle: event.data.has_ticket_commission,
                        msg: "3%/CAP",
                        isChanged:
                          updatedProperties.includes("ticket_commission"),
                      },
                    ]}
                    header={{
                      title: "VARIABLE.EXPENSES.TOTAL",
                      value: amountDisplay(calculateTotal()),
                      isChanged: updatedProperties.includes("variables_total"),
                    }}
                    noBlue={false}
                    onInputChange={handleUpdateVariablesTable}
                  /> */}
                </div>
                <div className="w-1/2 flex flex-col">
                  <TextAreaTable
                    header={"NOTES"}
                    value={event.data.offer_notes}
                    onInputChange={(accessor, value) =>
                      handleUpdateEvent("offer_notes", value)
                    }
                  />
                  <EditableTable
                    rows={[
                      {
                        header: "DOORS",
                        value: localTime(event.data.door_time),
                        accessor: "door_time",
                        format: "time",
                        isChanged: updatedProperties.includes("door_time"),
                      },
                      {
                        header: "OPENER",
                        value: localTime(event.data.opener_time),
                        accessor: "opener_time",
                        format: "time",
                        isChanged: updatedProperties.includes("opener_time"),
                      },
                      {
                        header: "HEADLINER",
                        value: localTime(event.data.headliner_time),
                        accessor: "headliner_time",
                        format: "time",
                        isChanged: updatedProperties.includes("headliner_time"),
                      },
                      {
                        header: "CURFEW",
                        value: localTime(event.data.curfew_time),
                        accessor: "curfew_time",
                        format: "time",
                        isChanged: updatedProperties.includes("curfew_time"),
                      },
                    ]}
                    header={{ title: "TIMES" }}
                    noBlue={true}
                    onInputChange={handleUpdateTime}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-8">&nbsp;</div>
        {/* <ExpensesTable id={id} /> */}
      </div>
    </div>
  );
};
