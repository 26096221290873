import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { Header } from "@/components/Header";
import { TwitterHandle } from "@/components/TwitterHandle";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { EventTable } from "@/components/EventDisplay/EventTable";
import { useRecordVisit } from "@/queries/visit";
import LinkDisplay from "@/components/Links/LinkDisplay";
import CommentDisplay from "@/components/Comments/CommentDisplay";
import FileDisplay from "@/components/Files/FileDisplay";
import ImageDisplay from "@/components/Images/ImageDisplay";

import {
  getArtist,
  getArtistPeople,
  toggleArtistMainPOC,
  getArtistEvents,
  getArtistPastEvents,
  getArtistLinks,
  getArtistLinkSettings,
  deleteArtistLink,
  getArtistComments,
  getArtistCommentSettings,
  createArtistComment,
  getArtistFiles,
  getArtistFileSettings,
  deleteArtistFile,
  getArtistImages,
  getArtistImageSettings,
  deleteArtistImage,
} from "@/queries/artists";

import { useParams, useNavigate } from "react-router-dom";
import { usePerms } from "@/components/Auth";
import People from "@/components/People/People";
import { BreadCrumbs } from "@/components/BreadCrumbs";
import { usePageTitle } from "@/utils/pagetitle";

export const ArtistDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const artist = useQuery(["artist-detail", id], () => getArtist(id));
  const people = useQuery(["artist", "people", id], () => getArtistPeople(id));
  const events = useQuery(["artist", "events", id], () => getArtistEvents(id));
  const past_events = useQuery(["artist", "events", "past", id], () =>
    getArtistPastEvents(id)
  );
  const links = useQuery(
    ["artist", "links", id],
    () => getArtistLinks(id),
    getArtistLinkSettings
  );
  const comments = useQuery(
    ["artist", "comments", id],
    () => getArtistComments(id),
    getArtistCommentSettings
  );
  const files = useQuery(
    ["artist", "files", id],
    () => getArtistFiles(id),
    getArtistFileSettings
  );
  const images = useQuery(
    ["artist", "images", id],
    () => getArtistImages(id),
    getArtistImageSettings
  );
  const { perms } = usePerms();
  const queryClient = useQueryClient();
  const visitMutation = useRecordVisit();

  usePageTitle(
    artist.isSuccess
      ? `/c${String.fromCharCode(92)}${artist.data.name}`
      : `/c${String.fromCharCode(92)}ave_good...`
  );

  // Comments
  const commentMutation = useMutation({
    mutationFn: (data) => {
      console.log("---> creating comment");
      console.dir(data);

      return createArtistComment(id, data);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(["artist", "comments", id]);
    },
  });

  const handleCommentSubmit = (data) => {
    commentMutation.mutate(data);
  };

  // Toggle main POC flag
  const mutation = useMutation({
    mutationFn: (id) => {
      console.log("---> toggling person", id);

      return toggleArtistMainPOC(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(["artist", "people"]);
      queryClient.invalidateQueries(["artists"]);
    },
  });

  useEffect(() => {
    if (artist.isSuccess) {
      visitMutation.mutate({
        itemID: artist.data.id,
        itemType: "artist",
        itemURL: `/artists/${artist.data.id}`,
      });
    }
  }, [artist.isSuccess]);

  if (artist.isLoading) {
    return <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>;
  }
  const vendors = artist.data.vendors;

  const artistAgent = vendors.filter(
    (vendor) => vendor.is_agency && vendor.active && vendor.is_active
  );

  const firstMatchedVendor = artistAgent.length > 0 ? artistAgent[0] : null;

  return (
    <>
      <Header />
      <div
        className="
        flex flex-col px-6 py-8 mx-auto md:h-screen lg:py-0 text-black
    "
      >
        <BreadCrumbs
          links={[
            { text: "Artists", url: "/artists" },
            { text: artist.data.name },
          ]}
        />

        <div className="py-6 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1 flex-wrap">
            <h2 className="text-2xl font-bold leading-7 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight flex">
              <p>
                {images.data && images.data.length > 0 && (
                  <img
                    width="200"
                    height="200"
                    src={images.data[0].image_url}
                  />
                )}
                {artist.data.name}
              </p>
            </h2>
            <h3 className="my-2 text-gray-600">{artist.data.address}</h3>
            <h3 className="my-2 text-gray-600">
              {artist.data.city && <>{artist.data.city},</>} {artist.data.state}{" "}
              {artist.data.zipcode}
            </h3>
            <TwitterHandle url={artist.data.twitter} />
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0 justify-start align-top">
            {perms.data.edit_artists && (
              <button
                type="button"
                onClick={() => {
                  navigate(`/artists/edit/${artist.data.id}`);
                }}
                className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Edit Artist
              </button>
            )}
          </div>
        </div>

        <div className="py-6">
          {artist.data.description && (
            <p className="mb-4">{artist.data.description}</p>
          )}

          {/* <table className="table-auto w-full"> */}
          <table>
            <tbody>
              <tr>
                <th className="text-left mr-8 dark:text-white w-1/4">Agent</th>
                <td className="pl-4 flex items-center pr-8">
                  <div>
                    {people.isSuccess && people.data.length > 0 ? (
                      people.data.find((person) => person.main_contact) ? (
                        <span>
                          {
                            people.data.find((person) => person.main_contact)
                              .first_name
                          }{" "}
                          {
                            people.data.find((person) => person.main_contact)
                              .last_name
                          }
                        </span>
                      ) : (
                        <span>
                          <button
                            type="button"
                            onClick={() =>
                              window.open(
                                `/people/${people.data[0].people_id}`,
                                "_blank"
                              )
                            }
                            className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                          >
                            {people.data[0].first_name}{" "}
                            {people.data[0].last_name}
                          </button>
                        </span>
                      )
                    ) : (
                      <span className="dark:text-white">No Agent found</span>
                    )}
                  </div>
                  <div>
                    {firstMatchedVendor ? (
                      <span>
                        <button
                          type="button"
                          onClick={() =>
                            window.open(
                              `/vendors/${firstMatchedVendor.id}`,
                              "_blank"
                            )
                          }
                          className="ml-3 inline-flex items-center rounded-md bg-ku-yellow px-3 py-2 text-sm font-semibold text-ku-blue shadow-sm hover:bg-ku-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ku-blue"
                        >
                          <h1>{firstMatchedVendor.name}</h1>
                          <p>{firstMatchedVendor.phone}</p>
                        </button>
                      </span>
                    ) : (
                      <p className="pl-4 dark:text-white">No Agency found</p>
                    )}
                  </div>
                </td>
              </tr>

              <tr>
                <th className="text-left mr-8 dark:text-white w-1/4">URL</th>
                <td className="pl-7">
                  <a
                    href={artist.data.url}
                    target="_blank"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    {artist.data.url}
                  </a>
                </td>
              </tr>
              <tr>
                <th className="text-left mr-8 dark:text-white w-1/4">Email</th>
                <td className="pl-7">{artist.data.email}</td>
              </tr>
              <tr>
                <th className="text-left mr-8 dark:text-white w-1/4">Phone</th>
                <td className="pl-7">{artist.data.phone}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="py-6">
          <ImageDisplay
            images={images}
            addURL={`/artists/images/add/${id}`}
            deleteQuery={deleteArtistImage}
            editable={perms.data.edit_artists}
          />
          <People
            people={people}
            toggleMainPOC={mutation}
            addURL={`/artists/people/add/${artist.data.id}`}
            itemType="artist"
            itemId={artist.data.id}
            editable={perms.data.edit_artists}
          />
        </div>

        <div className="py-6">
          <h3 className="text-xl font-semibold mb-6">Events</h3>
          {events.isLoading && <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>}
          {events.isSuccess && <EventTable events={events.data} />}
        </div>

        <div className="py-6">
          <h3 className="text-xl font-semibold mb-6 dark:text-white">
            Past Events
          </h3>
          {past_events.isLoading && <div className="text-center text-gray-700 dark:text-gray-300">Loading...</div>}
          {past_events.isSuccess && (
            <EventTable events={past_events.data} past={true} />
          )}
        </div>

        <div className="py-6">
          <LinkDisplay
            links={links}
            addURL={`/artists/link/add/${id}`}
            deleteQuery={deleteArtistLink}
            editable={perms.data.edit_artists}
          />
        </div>

        <div className="py-6">
          <FileDisplay
            files={files}
            addURL={`/artists/file/add/${id}`}
            deleteQuery={deleteArtistFile}
            editable={perms.data.edit_artists}
          />
        </div>

        <div className="py-6">
          <CommentDisplay
            comments={comments}
            handleAddComment={handleCommentSubmit}
          />
        </div>
      </div>
    </>
  );
};
