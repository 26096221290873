import React from 'react';

export function PPeopleIcon({ person }) {

  const getImageUrl = () => {
    if (person?.data?.user?.photo) {
      return person.data.user.photo;
    } else {
      return person.image_url;
    }

    if (person?.people_images && person.people_images.length > 0) {
      return person.people_images[0].image;
    } else {
      return person.image_url || null;
    }
  };

  const imageUrl = getImageUrl();

  const initials = person?.first_name && person?.last_name 
    ? `${person.first_name[0]}${person.last_name[0]}`.toUpperCase()
    : person?.first_name
      ? person.first_name[0].toUpperCase()
      : '?';


  return (
    <div className="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
      {imageUrl ? (
        <div
          className="w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
      ) : (
        <span className="text-xl font-semibold text-gray-500">
          {initials}
        </span>
      )}
    </div>
  );
}

// Keep the old version for backwards compatibility
export function PeopleIcon({ imageUrl }) {
  return (
    <div
      className="w-12 h-12 rounded-full bg-cover bg-center"
      style={{ backgroundImage: `url(${imageUrl})` }}
    ></div>
  );
}
